const API_ROUTES = {
  SESSION: {
    GET_APP_DICTIONARIES: "/Session/appDictionaries",
    AD_LOGIN: "/Session/adLogin",
    USER_LOGIN: "/Session/userLogin",
    DELEGATED_LOGIN: "/Session/delegatedLogin",
  },
  ASSISTANT_TAKS: {
    GET_MADEBYME: "/AssistantTask/get/madebyme",
    GET: "/AssistantTask/get",
    INSERT: "/AssistantTask/new",
    UPDATE: "/AssistantTask/edit",
    DELETE: "/AssistantTask/delete",
  },
  NOTIFICATIONS: {
    GET_MADEBYME: "/Notification/get/madebyme",
    GET: "/Notification/get",
    INSERT: "/Notification/new",
    UPDATE: "/Notification/edit",
    DELETE: "/Notification/delete",
  },
  BUSINESS_ITEMS: {
    GET_MADEBYME: "/BusinessItem/get/madebyme",
    GET: "/BusinessItem/get",
    INSERT: "/BusinessItem/new",
    UPDATE: "/BusinessItem/edit",
    DELETE: "/BusinessItem/delete",
  },
  FILES: {
    GET_MADEBYME: "/File/get/madebyme",
    GET: "/File/get",
    INSERT: "/File/new",
    UPDATE: "/File/edit",
    DELETE: "/File/delete",
    UPLOAD_BLOB: "/File/UploadFileBlob",
    DELETE_BLOB: "/File/DeleteFileBlob",
    GENERATE_SAS_TOKEN: "/File/GenerateSASToken",
    DOWNLOAD_BLOB: "/File/DownloadBlob",
  },
  GRAPH: {
    GET_GROUPS_IN_ACTIVE_DIRECTORY: "/Graph/getGroupsInActiveDirectory",
    GET_USER_GROUPS_MEMBERSHIP_IDENTIFIERS:
      "/Graph/getUserGroupsMembershipIdentifiers",
    GET_USER_GROUPS_MEMBERSHIP: "/Graph/getUserGroupsMembership",
    GET_USERS_IN_ACTIVE_DIRECTORY: "/Graph/getUsersInActiveDirectory",
  },
  GENERIC: {
    INFO_ON_AUTHOR: "/Generic/InfoOnAuthor",
    WRITE_LOG: "/Generic/writeLog",
    GET_SESSION_MIAGRATION_TOKEN: "/Generic/GetSessionMigrationToken",
    GET_CHAT_GPT_RESPONSE: "/Generic/ChatGPT",
  },
};

export default API_ROUTES;
