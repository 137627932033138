import { useEffect, useState } from "react";
import CustomTooltip from "../CustomTooltip/CustomTooltip";

export interface IPaginatorProps {
  max: number;
  emitPage: (page: number, perPage: number) => void;
}

export const PAGE_SIZE_OPTIONS = [10, 15, 20, 50, 100];

const Paginator = (props: IPaginatorProps) => {
  const DEFAULT_INITIAL_PAGES = 10;
  const [page, setPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(DEFAULT_INITIAL_PAGES);

  useEffect(() => {
    props.emitPage(page, perPage);
  }, [page, perPage]);

  const maxPage = Math.ceil(props.max / perPage);
  const currPage = props.max === 0 ? 0 : page + 1;
  const displayBottom = perPage * page + 1;
  const displayTop = currPage * perPage;
  const maxDisplay = displayTop > props.max ? props.max : displayTop;
  const minDisplay = displayBottom > props.max ? props.max : displayBottom;
  return (
    <div>
      {props.max > PAGE_SIZE_OPTIONS[0] && (
        <div className="pagination-main-wrap">
          <CustomTooltip
            disabled={page === 0}
            onClick={() => {
              setPage(page - 1);
            }}
            isButton
            content={"Previous page"}
            iconName="ChevronLeft"
          />
          <div style={{ opacity: "0.6" }}>
            Page {currPage} of {maxPage}, rows from {minDisplay} to {maxDisplay}{" "}
            of {props.max}, per page:
            <select
              defaultValue={DEFAULT_INITIAL_PAGES}
              className="paginator-select"
              onChange={(e: any) => {
                if (e) {
                  setPage(0);
                  setPerPage(e.target.value);
                }
              }}
            >
              {PAGE_SIZE_OPTIONS.map((x: number, i: number) => {
                return (
                  <option key={i} value={x}>
                    {x}
                  </option>
                );
              })}
            </select>
          </div>
          <CustomTooltip
            disabled={currPage >= maxPage}
            isButton
            onClick={() => {
              setPage(page + 1);
            }}
            content="Next page"
            iconName="ChevronRight"
          />
        </div>
      )}
    </div>
  );
};

export default Paginator;
