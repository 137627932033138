import API_ROUTES from "../../APIRoutes";
import { AjaxService, IAPIRequest, IAPIResponse } from "../AjaxService";
import { BuildParameters } from "../ApiService";

const SessionController = {
  GetAppDictionaries: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = API_ROUTES.SESSION.GET_APP_DICTIONARIES;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  LoginWithAzureAD: async (
    adToken: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(API_ROUTES.SESSION.AD_LOGIN, []);
    request.method = "POST";
    request.headers = { "Content-Type": "application/json" };
    request.payload = { adToken };

    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  LoginWithDelegatedCredenials: async (
    entityIdentifier: string,
    secret: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(API_ROUTES.SESSION.DELEGATED_LOGIN, [
      ["entityIdentifier", entityIdentifier],
      ["secret", secret],
    ]);
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
};

export default SessionController;
