import {
  GenericAction,
  SET_APP_DICTIONARIES,
  SET_MTA_TOKEN,
  SET_TOKEN,
  SET_USER,
} from "./GenericAction";
import { GenericState } from "./GenericState";

export const getLoggedUser = () => {
  let u = localStorage.getItem("loggedUser");
  if (u) {
    return JSON.parse(u);
  } else {
    return undefined;
  }
};

export const getToken = () => {
  let u = localStorage.getItem("sessionToken");
  if (u) {
    return u;
  } else {
    return undefined;
  }
};

export const getMyTeamsAssistantToken = () => {
  let u = localStorage.getItem("myteams-assistant-sessionToken");
  if (u) {
    return u;
  } else {
    return undefined;
  }
};

const DEFAULT_STATE: GenericState = {
  user: getLoggedUser(),
  token: getToken(),
  myTeamsAssistantToken: getMyTeamsAssistantToken(),
  appDictionaries: [],
};

export const genericReducer = (
  state: GenericState = DEFAULT_STATE,
  action: GenericAction
): GenericState => {
  let res = genericReducerInner(state, action);
  (window as any)["reducerGenericState"] = res;
  return res;
};

const genericReducerInner = (
  state: GenericState = DEFAULT_STATE,
  action: GenericAction
): GenericState => {
  switch (action.type) {
    case SET_USER:
      if (action.payload) {
        localStorage.setItem("loggedUser", JSON.stringify(action.payload));
      } else {
        localStorage.removeItem("loggedUser");
      }
      return {
        ...state,
        user: action.payload,
      };

    case SET_APP_DICTIONARIES:
      return {
        ...state,
        appDictionaries: action.payload,
      };
    case SET_TOKEN:
      if (action.payload) {
        localStorage.setItem("sessionToken", action.payload);
      } else {
        localStorage.removeItem("sessionToken");
      }
      return {
        ...state,
        token: action.payload,
      };
    case SET_MTA_TOKEN:
      if (action.payload) {
        localStorage.setItem("myteams-assistant-sessionToken", action.payload);
      } else {
        localStorage.removeItem("myteams-assistant-sessionToken");
      }
      return {
        ...state,
        myTeamsAssistantToken: action.payload,
      };
    default:
      return state;
  }
};
