import API_ROUTES from "../../APIRoutes";
import { AjaxService, IAPIRequest, IAPIResponse } from "../AjaxService";

const GraphController = {
  GetGroupsInActiveDirectory: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = API_ROUTES.GRAPH.GET_GROUPS_IN_ACTIVE_DIRECTORY;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  GetUserGroupsMembershipIdentifiers: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = API_ROUTES.GRAPH.GET_USER_GROUPS_MEMBERSHIP_IDENTIFIERS;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  GetUserGroupsMembership: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = API_ROUTES.GRAPH.GET_USER_GROUPS_MEMBERSHIP;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  GetUsersInActiveDirectory: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = API_ROUTES.GRAPH.GET_USERS_IN_ACTIVE_DIRECTORY;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
};

export default GraphController;
