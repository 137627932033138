import API_ROUTES from "../../APIRoutes";
import {
  IFileEntryInterface,
  IFileEntryInterfaceUpdate,
} from "../../Models/IFileEntry";
import { AjaxService, IAPIRequest, IAPIResponse } from "../AjaxService";
import { BuildParameters } from "../ApiService";

const FileController = {
  GetFileEntryMadeByMe: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = API_ROUTES.FILES.GET_MADEBYME;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  GetFileEntry: async (
    fileId?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(API_ROUTES.FILES.GET, [["fileId", fileId]]);
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  InsertFileEntry: async (
    itemToAdd: IFileEntryInterface,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(API_ROUTES.FILES.INSERT, []);
    request.method = "POST";
    request.payload = itemToAdd;
    request.headers = { "Content-Type": "application/json" };
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  UpdateFileEntry: async (
    itemToUpdate: IFileEntryInterfaceUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(API_ROUTES.FILES.UPDATE, []);
    request.method = "PUT";
    request.payload = itemToUpdate;
    request.headers = { "Content-Type": "application/json" };
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  DeleteFileEntry: async (
    fileIdentifier: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.method = "DELETE";
    request.url = BuildParameters(API_ROUTES.FILES.DELETE, [
      ["fileIdentifier", fileIdentifier],
    ]);
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  GetSASString: async (
    containerName: string,
    blobId: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(API_ROUTES.FILES.GENERATE_SAS_TOKEN, [
      ["containerName", containerName],
      ["blobId", blobId],
    ]);
    let response = await AjaxService.APICall(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DeleteFileBlob: async (
    containerName: string,
    blobId: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(API_ROUTES.FILES.GENERATE_SAS_TOKEN, [
      ["containerName", containerName],
      ["blobId", blobId],
    ]);
    request.method = "DELETE";
    let response = await AjaxService.APICall(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  UploadFileBlob: async (
    containerName: string,
    files: File[],
    callback?: (response: IAPIResponse) => void
  ) => {
    var formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.method = "POST";
    request.url = BuildParameters(API_ROUTES.FILES.UPLOAD_BLOB, [
      ["containerName", containerName],
    ]);

    request.payload = formData;
    request.dontStringify = true;
    let response = await AjaxService.APICall(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  DownloadBlob: async (
    blobSasUri: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(API_ROUTES.FILES.DOWNLOAD_BLOB, []);
    request.method = "POST";
    request.payload = { param: blobSasUri };
    request.headers = { "Content-Type": "application/json" };
    request.responseSkipToJson = true;
    let response = await AjaxService.APICall(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default FileController;
