import {
  ISharepointList,
  ISharepointListItem,
} from "../Models/ISharepointList";
import {
  ISharepointListContent,
  ISharepointListContentItem,
} from "../Models/ISharepointListContent";
import {
  ISharepointSite,
  ISharepointSiteItem,
} from "../Models/ISharepointSite";
import AuthenticationHelper from "./AuthenticationHelper";

const SharepointGetAllSites = async (token: string) => {
  let result: any = await fetch(
    "https://graph.microsoft.com/v1.0/sites?search=*",
    { headers: { Authorization: `Bearer ${token}` } }
  );

  if (result.status < 300) {
    let list = (await result.json()) as ISharepointSite;
    return list.value as ISharepointSiteItem[];
  }

  if (result.status === 401) {
    AuthenticationHelper.handleTokenExpired();
  }

  return [];
};

const SharepointGetAllListsForSite = async (token: string, siteId: string) => {
  let result: any = await fetch(
    "https://graph.microsoft.com/v1.0/sites/" + siteId + "/lists/",
    { headers: { Authorization: `Bearer ${token}` } }
  );

  if (result.status < 300) {
    let list = (await result.json()) as ISharepointList;
    return list.value as ISharepointListItem[];
  }

  if (result.status === 401) {
    AuthenticationHelper.handleTokenExpired();
  }

  return [];
};

const SharepointGetAllItemsForList = async (
  token: string,
  siteId: string,
  listId: string
) => {
  let result: any = await fetch(
    "https://graph.microsoft.com/v1.0/sites/" +
      siteId +
      "/lists/" +
      listId +
      "/items",
    { headers: { Authorization: `Bearer ${token}` } }
  );

  if (result.status < 300) {
    let list = (await result.json()) as ISharepointListContent;
    return list.value as ISharepointListContentItem[];
  }

  if (result.status === 401) {
    AuthenticationHelper.handleTokenExpired();
  }

  return [];
};

const GraphApi: any = {
  SharepointGetAllSites,
  SharepointGetAllListsForSite,
  SharepointGetAllItemsForList,
};

export default GraphApi;
