import { useEffect, useState } from "react";
import { ISharepointListContentItem } from "../../Models/ISharepointListContent";
import { ISharepointSiteItem } from "../../Models/ISharepointSite";
import "./DocumentsAggregatedViewPage.scss";
import { useSelector } from "react-redux";
import { GlobalState } from "../../Redux/RootReducer";
import GraphApi from "../../Utils/GraphApi";
import { ISharepointListItem } from "../../Models/ISharepointList";
import { IFileEntry } from "../../Models/IFileEntry";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import { InjectAuthorData } from "../../Utils/DataSupportAPI";
import Loadable from "../../Components/Loadable/Loadable";
import TeamsTable from "../../Components/TeamsTable/TeamsTable";
import DownloadBlobButton from "../../Components/DownloadBlobButton/DownloadBlobButton";
import { nameFromWebUrl } from "../SharepointDocumentsTabPage/SharepointDocumentsTabPage";
import TeamsButton from "../../Components/TeamsButton/TeamsButton";

const BLOB_ICON = "/doc1.svg";
const SHAREPOINT_ICON = "/doc2.svg";

export interface IAggregatedFileDescriptor {
  FileID: number;
  AppSource: "blob" | "sharepoint";
  FileDescriptor: string;
  Source: string;
  ExternalLink: string;
  Title: string;
  Description: string;
  CreatedByEmail: string;
  CreatedDate: string;
  UpdatedDate: string;
}

const DocumentsAggregatedViewPage = () => {
  const [loadingSharepoint, setLoadingSharepoint] = useState<boolean>(false);

  const [items, setItems] = useState<ISharepointListContentItem[]>([]);
  const token: string | undefined = useSelector(
    (state: GlobalState) => state.generic.token
  );

  const [files, setFiles] = useState<IFileEntry[]>([]);
  const [loadingFiles, setLoadingFiles] = useState<boolean>(true);
  const [loadableLabel, setLoadableLabel] = useState<string>("");

  const GetFiles = () => {
    setLoadingFiles(true);
    ApiService.FileController.GetFileEntry(
      undefined,
      async (response: IAPIResponse) => {
        if (response.error === null) {
          setFiles((await InjectAuthorData(response.parsed)) as any);
        }
        setLoadingFiles(false);
      }
    );
  };

  const processDataItems = (
    sites: ISharepointSiteItem[],
    rawItems: ISharepointListContentItem[]
  ) => {
    let processedItems: any = [];
    for (let i = 0; i < rawItems.length; i++) {
      let ctype: string = rawItems[i].contentType.name.toLocaleLowerCase();
      if (ctype.includes("document") || true) {
        let item = rawItems[i];
        let newItem: any = {
          ...item,
        };
        newItem["siteName"] = sites.find(
          (x) => x.id === item.parentReference.siteId
        )?.displayName;
        newItem["plainName"] = nameFromWebUrl(item.webUrl);
        processedItems.push(newItem);
      }
    }
    return processedItems;
  };

  const getDocumentsFromAllSharepointsSources = async () => {
    setLoadableLabel("Loading...");

    setLoadingSharepoint(true);

    // all sites the user is allowed to access
    let sites: ISharepointSiteItem[] = await GraphApi.SharepointGetAllSites(
      token
    );

    // get all the lists from all the sites
    let listResult: ISharepointListItem[] = [];
    for (let i = 0; i < sites.length; i++) {
      setLoadableLabel(
        "Getting sites " + Math.floor((i / sites.length) * 100) + "%"
      );
      let result: ISharepointListItem[] =
        await GraphApi.SharepointGetAllListsForSite(token, sites[i].id);

      listResult = listResult.concat(
        result.filter(
          (x) =>
            x.list.template === "documentLibrary" && x.list.hidden === false
        )
      );
    }

    // get all files from each document library
    let items: ISharepointListContentItem[] = [];
    for (let i = 0; i < listResult.length; i++) {
      setLoadableLabel(
        "Getting documents " + Math.floor((i / listResult.length) * 100) + "%"
      );

      let result: ISharepointListContentItem[] =
        await GraphApi.SharepointGetAllItemsForList(
          token,
          listResult[i].parentReference.siteId,
          listResult[i].id
        );

      items = items.concat(result);
    }

    setItems(
      processDataItems(
        sites,
        items.filter(
          (x: ISharepointListContentItem) => x.contentType.name !== "Folder"
        )
      )
    );
    setLoadableLabel("Almost done...");
    setLoadingSharepoint(false);
  };

  useEffect(() => {
    getDocumentsFromAllSharepointsSources();
    GetFiles();
  }, []);

  const aggregatedFileDescriptors: IAggregatedFileDescriptor[] = [];

  // prepare files for preview from azure
  files.forEach((file: IFileEntry) => {
    aggregatedFileDescriptors.push({
      FileID: file.ReferenceID,
      AppSource: "blob",
      Source: "Azure Storage",
      FileDescriptor: file.ReferenceIdentifier,
      Title: file.ReferenceTitle,
      Description: file.ReferenceDescription,
      CreatedDate: file.CreatedDate,
      UpdatedDate: file.UpdatedDate,
      CreatedByEmail: file.CreatedByEmail ?? "Unknown",
      ExternalLink: file.ReferenceExternalSystemLink,
    });
  });

  // prepare files for preview from sharepoint
  items.forEach((item: ISharepointListContentItem, i: number) => {
    aggregatedFileDescriptors.push({
      FileID: 0,
      AppSource: "sharepoint",
      Source: "Site " + item.siteName,
      FileDescriptor: "",
      Title: item.plainName,
      Description: item.contentType.name,
      CreatedDate: item.createdDateTime,
      UpdatedDate: item.lastModifiedDateTime,
      CreatedByEmail: item.createdBy.user.email,
      ExternalLink: item.webUrl,
    });
  });

  const sortedItems = aggregatedFileDescriptors.sort(
    (a: IAggregatedFileDescriptor, b: IAggregatedFileDescriptor) => {
      return b.CreatedDate.localeCompare(a.CreatedDate);
    }
  );

  const TranferSession = (targetType: string) => {
    ApiService.GenericController.GetSessionMigrationToken(
      (response: IAPIResponse) => {
        if (response.error === null) {
          let targetUrl: string =
            "https://demoteams-integrations.r53.avvale.com/#/transferSession?redirectAfter=" +
            encodeURIComponent(targetType);
          targetUrl += "&migrationToken=" + response.parsed.Param;
          let a = document.createElement("a");
          a.href = targetUrl;
          a.target = "_blank";
          a.click();
        }
      }
    );
  };

  return (
    <div>
      <Loadable
        label={loadableLabel}
        isLoading={loadingFiles || loadingSharepoint}
        content={
          <div>
            <TeamsButton
              primary
              text="Upload new document"
              onClick={() => {
                TranferSession("/documents");
              }}
            />
            <br />
            <TeamsTable
              handleLinkClick={(item: any, link: string) => {
                if (item.AppSource === "sharepoint") {
                  let a = document.createElement("a");
                  a.href = link;
                  a.target = "_blank";
                  a.click();
                }

                if (item.AppSource === "blob") {
                  let targetUrl =
                    link + "?itemId=" + item.FileID + "&itemType=FileEntry";

                  ApiService.GenericController.GetSessionMigrationToken(
                    (response: IAPIResponse) => {
                      if (response.error === null) {
                        let a = document.createElement("a");
                        a.href =
                          targetUrl +
                          "&migrationToken=" +
                          response.parsed.Param;
                        a.target = "_blank";
                        a.click();
                      }
                    }
                  );
                }
              }}
              columns={[
                {
                  name: "Source",
                  dataType: "string",
                  fieldName: "source",
                  size: "small",
                  onRender: (item: IAggregatedFileDescriptor) => {
                    let icon = <div></div>;
                    if (item.AppSource === "blob") {
                      icon = (
                        <div className="inline-source-icon">
                          <img src={BLOB_ICON} />
                        </div>
                      );
                    }
                    if (item.AppSource === "sharepoint") {
                      icon = (
                        <div className="inline-source-icon">
                          <img src={SHAREPOINT_ICON} />
                        </div>
                      );
                    }

                    return (
                      <div className="source-icon-container">
                        <div>{icon}</div>
                        <div>{item.Source} </div>
                      </div>
                    );
                  },
                },
                {
                  name: "Title",
                  dataType: "string",
                  fieldName: "Title",
                  size: "medium",
                },
                {
                  name: "Description",
                  dataType: "string",
                  fieldName: "Description",
                  size: "medium",
                },
                {
                  name: "Link",
                  dataType: "link",
                  fieldName: "ExternalLink",
                  size: "medium",
                },
                {
                  name: "Download",
                  dataType: "string",
                  fieldName: "FileDescriptor",
                  size: "medium",
                  onRender: (item: IAggregatedFileDescriptor) => {
                    let container: string =
                      item.FileDescriptor.split("____")[0];
                    return (
                      <DownloadBlobButton
                        disabled={!item.FileDescriptor}
                        container={container}
                        fileReference={item.FileDescriptor}
                      />
                    );
                  },
                },
                {
                  name: "Author",
                  dataType: "string",
                  fieldName: "CreatedByEmail",
                  size: "medium",
                },
                {
                  name: "Created On",
                  dataType: "date/time",
                  fieldName: "CreatedDate",
                  size: "medium",
                },
                {
                  name: "Updated On",
                  dataType: "date/time",
                  fieldName: "UpdatedDate",
                  size: "medium",
                },
              ]}
              rowData={sortedItems}
            />
          </div>
        }
      />
    </div>
  );
};

export default DocumentsAggregatedViewPage;
