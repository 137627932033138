import React, { useEffect, useState } from "react";
import {
  Route,
  Router,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { GlobalState } from "./Redux/RootReducer";
import { useSelector } from "react-redux";
import { IUser } from "./Models/IUser";
import { IsBrowserMode } from "./App";
import { InitDynamicAppRoutes, IAppRouteItem, IAppTabs } from "./AppRoutes";
import BetterPivot from "./Components/BetterPivot/BetterPivot";
import { IAppDictionary } from "./Models/IAppDictionary";
import { Tools } from "./Utils/Tools";
import { Label } from "@fluentui/react";
import DebugPage from "./Pages/DebugPage/DebugPage";
import TeamsPivot from "./Components/TeamsPivot/TeamsPivot";
import TeamsButton from "./Components/TeamsButton/TeamsButton";
import ChatGPTBox from "./Components/ChatGPTBox/ChatGPTBox";

function AppLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const [debugCounter, setDebugCounter] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const appDictionaries: IAppDictionary[] = useSelector(
    (state: GlobalState) => state.generic.appDictionaries
  );
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.generic.user
  );
  let AppTabs = InitDynamicAppRoutes(appDictionaries);
  const [activeTab, setActiveTab] = useState<IAppTabs | undefined>();

  useEffect(() => {
    setActiveTab(
      AppTabs.find((x: IAppTabs) =>
        window.location.pathname.includes(x.tabPath)
      )
    );
  }, [location]);

  const reload = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  const getUniquePaths = () => {
    let routes: IAppRouteItem[] = [];

    activeTab?.tabChildRoutes.forEach((x: IAppRouteItem) => {
      if (!routes.some((y: IAppRouteItem) => y.path === x.path)) {
        routes.push(x);
      }
    });

    return routes;
  };

  return (
    <div>
      {IsBrowserMode() && (
        <div className="mock-teams-tab-wrapper">
          <div className="mock-teams-tab-wrapper-row">
            {AppTabs.map((x: IAppTabs, i: number) => {
              return (
                <div
                  className="mock-teams-tab"
                  key={i}
                  onClick={() => {
                    setActiveTab(x);
                    reload();
                    if (x.tabChildRoutes.length > 0) {
                      let child = x.tabChildRoutes[0];
                      navigate(
                        child.path + (child.search ? "?" + child.search : "")
                      );
                    } else {
                      navigate(x.tabPath);
                    }
                  }}
                >
                  {x.tabName}
                </div>
              );
            })}
          </div>

          <div>
            <span>{loggedUser?.UserEmail}</span>
            <TeamsButton
              text="Logout"
              primary
              onClick={() => {
                document.body.dispatchEvent(new Event("dologout"));
              }}
            />
          </div>
        </div>
      )}
      {!loading && activeTab && (
        <div>
          <TeamsPivot
            onSelectedKey={(key: string) => {
              navigate(key);
            }}
            voices={
              activeTab?.tabChildRoutes
                .filter((x: IAppRouteItem) => x.invisibleTab !== true)
                .map((x: IAppRouteItem) => {
                  return {
                    key: x.path + (x.search ? "?" + x.search : ""),
                    text: x.routeName,
                  };
                }) ?? []
            }
          />

          {debugCounter >= 10 && (
            <DebugPage
              onReset={() => {
                setDebugCounter(0);
              }}
            />
          )}
          {debugCounter < 10 && (
            <Routes>
              {getUniquePaths().map((x: IAppRouteItem, i: number) => {
                return (
                  <Route
                    key={i}
                    element={
                      <div className="default-teams-app-container">
                        {x.element}
                      </div>
                    }
                    path={x.path}
                  />
                );
              })}
            </Routes>
          )}
        </div>
      )}
      <div
        className="version-label"
        onClick={() => {
          setDebugCounter(debugCounter + 1);
        }}
      >
        V 1.0.21
      </div>
      <ChatGPTBox />
    </div>
  );
}

export default AppLayout;
