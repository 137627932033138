import { IconButton, PrimaryButton } from "@fluentui/react";
import "./DownloadBlobButton.scss";
import { DownloadAzureBlob } from "../../Utils/DataSupportAPI";
import { useState } from "react";
import Loadable from "../Loadable/Loadable";

export interface IDownloadBlobButtonProps {
  container: string;
  fileReference: string;
  disabled?: boolean;
}

const DownloadBlobButton = (props: IDownloadBlobButtonProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div>
      <Loadable
        noPadding
        isLoading={loading}
        content={
          <IconButton
            disabled={props.disabled}
            iconProps={{ iconName: "Download" }}
            onClick={async () => {
              setLoading(true);
              await DownloadAzureBlob(props.container, props.fileReference);
              setLoading(false);
            }}
          />
        }
      />
    </div>
  );
};

export default DownloadBlobButton;
