import { useEffect, useState } from "react";
import "./SmartModal.scss";
import { DefaultButton, IconButton, PrimaryButton } from "@fluentui/react";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import TeamsButton from "../TeamsButton/TeamsButton";
import { Label } from "@fluentui/react-components";

export interface ISmartModalButtons {
  onClick: () => void;
  text: string;
  iconOnlyLabel?: string;
  iconName?: string;
  disabled?: boolean;
}

export interface ISmartModalProps {
  modalUniqueId: string;
  title?: string;
  onSubmit?: () => void;
  content: JSX.Element;
  buttons: ISmartModalButtons[];
  leftButtons?: ISmartModalButtons[];
}

export const SummonModal = (modalUniqueId: string) => {
  document.dispatchEvent(
    new CustomEvent("summon-modal", { detail: { id: modalUniqueId } })
  );
};

export const DismissModal = (modalUniqueId: string) => {
  if (modalUniqueId === "*") {
    let allOpenModals = Array.from(
      document.getElementsByClassName("smart-modal-outer-inkdrop")
    );
    if (allOpenModals.length > 0) {
      let last = allOpenModals[allOpenModals.length - 1];
      DismissModal(last.id.replace("modal-unique-", ""));
    }
  } else {
    document.dispatchEvent(
      new CustomEvent("dismiss-modal", { detail: { id: modalUniqueId } })
    );
  }
};

const SmartModal = (props: ISmartModalProps) => {
  const [visible, setVisible] = useState<boolean>(false);

  const summonModal = (e: any) => {
    if (e.detail.id === props.modalUniqueId) {
      setVisible(true);
    }
  };
  const dismissModal = (e: any) => {
    if (e.detail.id === props.modalUniqueId || e.detail.id === "*") {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("summon-modal", summonModal);
    document.addEventListener("dismiss-modal", dismissModal);

    return () => {
      document.removeEventListener("summon-modal", summonModal);
      document.removeEventListener("dismiss-modal", dismissModal);
    };
  }, []);

  const renderModalButton = (
    x: ISmartModalButtons,
    i: number,
    isLast: boolean
  ) => {
    if (x.iconOnlyLabel) {
      return (
        <CustomTooltip
          key={i}
          disabled={x.disabled}
          onClick={x.onClick}
          iconName={x.iconName ?? ""}
          content={<div>{x.iconOnlyLabel}</div>}
        />
      );
    } else {
      return isLast ? (
        <TeamsButton
          key={i}
          text={x.text}
          primary
          iconProps={{ iconName: x.iconName }}
          onClick={x.onClick}
          disabled={x.disabled}
        />
      ) : (
        <TeamsButton
          key={i}
          primary={false}
          text={x.text}
          iconProps={{ iconName: x.iconName }}
          onClick={x.onClick}
          disabled={x.disabled}
        />
      );
    }
  };

  return (
    <div>
      {visible && (
        <div
          className="smart-modal-outer-inkdrop"
          id={"modal-unique-" + props.modalUniqueId}
        >
          <div className="smart-modal-wrap dynamic-teams-theme-background-setter">
            <div className="smart-modal-title-line">
              <div className="smart-modal-title">
                <Label>{props.title}</Label>
              </div>
              <CustomTooltip
                iconName="Cancel"
                isButton
                content={"Cancel"}
                onClick={() => {
                  setVisible(false);
                }}
              />
            </div>

            {visible && (
              <div className="smart-modal-content">{props.content}</div>
            )}
            <div className="smart-modal-row-buttons-wrap">
              <div className="smart-modal-row-buttons">
                {(props.buttons ?? []).map(
                  (x: ISmartModalButtons, i: number) => {
                    return renderModalButton(
                      x,
                      i,
                      i === props.buttons.length - 1
                    );
                  }
                )}
              </div>
              <div className="smart-modal-row-buttons">
                {(props.leftButtons ?? []).map(
                  (x: ISmartModalButtons, i: number) => {
                    return renderModalButton(
                      x,
                      i,
                      i === props.buttons.length - 1
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SmartModal;
