interface APIItemPending {
  url: string;
  parameters: any;
  disableTimeout: boolean;
}

const GENERIC_ERROR: string = "Something went wrong";
const API_PIPELINE: any = {};
(window as any)["API_LOGS"] = [];

const getAPIQueueKey = (request: APIItemPending) => {
  return (
    (request.parameters.method ?? "GET") +
    ":" +
    request.url +
    ":" +
    (request.parameters.body ?? "")
  ).toLocaleLowerCase();
};

const getTimeout = () => {
  let devTimeout = localStorage.getItem("dev-api-timeout");
  if (devTimeout) {
    return +devTimeout;
  }

  return 15000;
};

const FetchWithTimeout = async (
  url: string,
  options: any,
  disableTimeout: boolean
) => {
  const { timeout = getTimeout() } = options;

  // normal fecth call
  if (disableTimeout) {
    try {
      //const response = await fetch(url, options);
      const response = await fetch(url, options);
      return response;
    } catch (e) {
      return {
        statusText: GENERIC_ERROR,
        status: 500,
        json: () => {
          return null;
        },
      };
    }
  }
  // timed out fecth call
  else {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    try {
      const response = await fetch(url, {
        ...options,
        signal: controller.signal,
      });
      clearTimeout(id);
      return response;
    } catch (e) {
      clearTimeout(id);
      return {
        statusText: GENERIC_ERROR,
        status: 500,
        json: () => {
          return null;
        },
      };
    }
  }
};

const FetchPipeline = async (
  url: string,
  parameters: any,
  disableTimeout: boolean
) => {
  let result = await FetchWithTimeout(url, parameters, disableTimeout);

  return result;
};

const AjaxPipeline = {
  FetchPipeline,
};

export default AjaxPipeline;
