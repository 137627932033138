import API_ROUTES from "../../APIRoutes";
import {
  IAssistantTask,
  IAssistantTaskInterface,
  IAssistantTaskInterfaceUpdate,
} from "../../Models/IAssistantTask";
import { AjaxService, IAPIRequest, IAPIResponse } from "../AjaxService";
import { BuildParameters } from "../ApiService";

const AssistantTaskController = {
  GetAssistantTaskMadeByMe: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = API_ROUTES.ASSISTANT_TAKS.GET_MADEBYME;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  GetAssistantTask: async (
    taskId?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(API_ROUTES.ASSISTANT_TAKS.GET, [
      ["taskId", taskId],
    ]);
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  InsertAssistantTask: async (
    taskToAdd: IAssistantTaskInterface,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(API_ROUTES.ASSISTANT_TAKS.INSERT, []);
    request.method = "POST";
    request.payload = taskToAdd;
    request.headers = { "Content-Type": "application/json" };
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  UpdateAssistantTask: async (
    taskToUpdate: IAssistantTaskInterfaceUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(API_ROUTES.ASSISTANT_TAKS.UPDATE, []);
    request.method = "PUT";
    request.payload = taskToUpdate;
    request.headers = { "Content-Type": "application/json" };
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  DeleteAssistantTask: async (
    taskId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.method = "DELETE";
    request.url = BuildParameters(API_ROUTES.ASSISTANT_TAKS.DELETE, [
      ["taskId", taskId],
    ]);
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
};

export default AssistantTaskController;
