import { IsBrowserMode } from "../../App";
import {
  makeStyles,
  SelectTabData,
  SelectTabEvent,
  shorthands,
  Tab,
  TabList,
} from "@fluentui/react-components";
import BetterPivot from "../BetterPivot/BetterPivot";
import "./TeamsPivot.scss";
import { useEffect, useState } from "react";

export interface ITeamsPivotProps {
  voices: { key: string; text: string; iconName?: string }[];
  onSelectedKey?: (key: string) => void;
}

const getInitialActiveKey = (
  voices: { key: string; text: string; iconName?: string }[]
) => {
  return voices.length > 0 ? voices[0].key : "";
};

const TeamsPivot = (props: ITeamsPivotProps) => {
  const [activeKey, setActiveKey] = useState<string>(
    getInitialActiveKey(props.voices)
  );

  return (
    <>
      {IsBrowserMode() && (
        <>
          <BetterPivot
            voices={props.voices}
            onSelectedKey={props.onSelectedKey}
          />
        </>
      )}
      {!IsBrowserMode() && (
        <>
          <TabList
            selectedValue={activeKey}
            onTabSelect={(event: SelectTabEvent, data: SelectTabData) => {
              if (props.onSelectedKey) {
                setActiveKey(data.value as string);
                props.onSelectedKey(data.value as string);
              }
            }}
          >
            {props.voices.map(
              (x: { key: string; text: string; iconName?: string }) => {
                return (
                  <Tab key={x.key} value={x.key}>
                    {x.text}
                  </Tab>
                );
              }
            )}
          </TabList>
        </>
      )}
    </>
  );
};

export default TeamsPivot;
