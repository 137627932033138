import { useEffect, useState } from "react";
import "./NotificationsTabPage.scss";
import { INotification } from "../../Models/INotification";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import Loadable from "../../Components/Loadable/Loadable";
import FluentDataDable from "../../Components/FluentDataDable/FluentDataDable";
import CallToActionButton from "../../Components/CallToActionButton/CallToActionButton";
import { GlobalState } from "../../Redux/RootReducer";
import { IAppDictionary } from "../../Models/IAppDictionary";
import { useSelector } from "react-redux";
import { InjectAuthorData } from "../../Utils/DataSupportAPI";
import TeamsTable from "../../Components/TeamsTable/TeamsTable";
import PriorityBubble from "../../Components/PriorityBubble/PriorityBubble";

const NotificationsTabPage = () => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const GetNotifications = () => {
    setLoading(true);
    ApiService.NotificationController.GetNotification(
      undefined,
      async (response: IAPIResponse) => {
        if (response.error === null) {
          setNotifications((await InjectAuthorData(response.parsed)) as any);
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    GetNotifications();
  }, []);

  return (
    <div>
      <Loadable
        isLoading={loading}
        content={
          <div>
            <TeamsTable
              handleLinkClick={(item: any, link: string) => {
                // new url
                let targetUrl =
                  link +
                  "?itemId=" +
                  item.NotificationId +
                  "&itemType=Notification";

                ApiService.GenericController.GetSessionMigrationToken(
                  (response: IAPIResponse) => {
                    if (response.error === null) {
                      let a = document.createElement("a");
                      a.href =
                        targetUrl + "&migrationToken=" + response.parsed.Param;
                      a.target = "_blank";
                      a.click();
                    }
                  }
                );
              }}
              columns={[
                {
                  name: "Title",
                  dataType: "string",
                  fieldName: "NotificationTitle",
                  size: "medium",
                },
                {
                  name: "Description",
                  dataType: "string",
                  fieldName: "NotificationDescription",
                  size: "medium",
                },
                {
                  name: "Link",
                  dataType: "link",
                  fieldName: "NotificationExternalSystemLink",
                  size: "medium",
                },
                {
                  name: "Priority",
                  dataType: "string",
                  fieldName: "PriorityLabel",
                  size: "medium",
                  onRender: (item: INotification) => {
                    return (
                      <div>
                        <PriorityBubble priority={item.NotificationPriority} />
                      </div>
                    );
                  },
                },
                {
                  name: "Author",
                  dataType: "string",
                  fieldName: "CreatedByEmail",
                  size: "medium",
                },
                {
                  name: "Created On",
                  dataType: "date/time",
                  fieldName: "CreatedDate",
                  size: "medium",
                },
                {
                  name: "Updated On",
                  dataType: "date/time",
                  fieldName: "UpdatedDate",
                  size: "medium",
                },
              ]}
              rowData={notifications}
            />
          </div>
        }
      />
    </div>
  );
};

export default NotificationsTabPage;
