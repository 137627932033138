import { useSelector } from "react-redux";
import { IAppDictionary } from "../../Models/IAppDictionary";
import "./PriorityBubble.scss";
import { GlobalState } from "../../Redux/RootReducer";

export interface IPriorityBubbleProps {
  priority: number;
}

export const priorityLabel = (
  appDictionaries: IAppDictionary[],
  priority: number
) => {
  let options: IAppDictionary[] = appDictionaries.filter(
    (x: IAppDictionary) =>
      x.DictionaryType === "priority-level" && x.DictionaryId == priority
  );

  return options.length > 0 ? options[0].DictionaryDisplayName : "Unknown";
};

const PriorityBubble = (props: IPriorityBubbleProps) => {
  const appDictionaries: IAppDictionary[] = useSelector(
    (state: GlobalState) => state.generic.appDictionaries
  );

  let label = priorityLabel(appDictionaries, props.priority);
  return (
    <div className={"priority-bubble-wrap-" + label.toLocaleLowerCase()}>
      <div>{label}</div>
    </div>
  );
};

export default PriorityBubble;
