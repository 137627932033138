import "./TeamsDataGrid.scss";
import {
  FolderRegular,
  EditRegular,
  OpenRegular,
  DocumentRegular,
  PeopleRegular,
  DocumentPdfRegular,
  VideoRegular,
} from "@fluentui/react-icons";
import {
  PresenceBadgeStatus,
  Avatar,
  DataGridBody,
  DataGridRow,
  DataGrid,
  DataGridHeader,
  Input,
  DataGridHeaderCell,
  DataGridCell,
  TableCellLayout,
  TableColumnDefinition,
  createTableColumn,
  InputOnChangeData,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { Link } from "@fluentui/react";
import Paginator from "../Paginator/Paginator";
import "./TeamsDataGrid.scss";
import TeamsButton from "../TeamsButton/TeamsButton";

export interface ITeamsDataGridProps {
  rowData: any[];
  columns: any[];
  onLinkClick: (item: any, link: string) => void;
}

const extractItemValueOnPath = (item: any, path: string) => {
  try {
    let dotPath: string[] = path.split(".");
    for (let i = 0; i < dotPath.length; i++) {
      item = item[dotPath[i]];
    }
    return item;
  } catch (e) {
    return null;
  }
};

const DEFAULT_INITIAL_PAGES = 10;

const TeamsDataGrid = (props: ITeamsDataGridProps) => {
  const [columns, setColumns] = useState<TableColumnDefinition<any>[]>([]);
  const [filterValue, setFilterValue] = useState<string>("");
  const [activeFilterValue, setActiveFilterValue] = useState<string>("");
  const [holdOn, setHoldOn] = useState<boolean>(false);
  const [pagination, setPagination] = useState<{
    page: number;
    perPage: number;
    paginating: boolean;
  }>({
    page: 0,
    perPage: DEFAULT_INITIAL_PAGES,
    paginating: false,
  });

  useEffect(() => {
    let cols: TableColumnDefinition<any>[] = [];

    props.columns.forEach((column: any) => {
      cols.push(
        createTableColumn<any>({
          columnId: column.fieldName,
          compare: (a, b) => {
            return a[column.fieldName].localeCompare(b[column.fieldName]);
          },
          renderHeaderCell: () => {
            return column.name;
          },
          renderCell: (item: any) => {
            let deepReadingValue: any = extractItemValueOnPath(
              item,
              column.fieldName
            );

            let component: JSX.Element = <div>{deepReadingValue}</div>;

            if (column.dataType === "date" && deepReadingValue) {
              component = (
                <div>{new Date(deepReadingValue).toLocaleDateString()}</div>
              );
            }
            if (column.dataType === "date/time" && deepReadingValue) {
              component = (
                <div>
                  {new Date(deepReadingValue).toLocaleDateString()}
                  <br />
                  <small>
                    {new Date(deepReadingValue).toLocaleTimeString()}
                  </small>
                </div>
              );
            }

            if (column.dataType === "link" && deepReadingValue) {
              component = (
                <Link
                  target={"_blank"}
                  to={"#"}
                  onClick={() => {
                    props.onLinkClick(item, deepReadingValue);
                  }}
                >
                  Link
                </Link>
              );
            }

            if (column.onRender) {
              component = column.onRender(item);
            }

            return (
              <TableCellLayout title={deepReadingValue}>
                {component}
              </TableCellLayout>
            );
          },
        })
      );
    });

    setColumns(cols);
  }, []);

  const filterItems = (rows: any, text: string) => {
    if (text === "") {
      return rows;
    }
    let acceptItems: any[] = [];

    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < props.columns.length; j++) {
        let field = props.columns[j].fieldName;
        if (field) {
          let data = rows[i][field];
          let value = data;
          if (data && data.value !== undefined) {
            value = data.value;
          }
          if (props.columns[j].dataType === "date" && value) {
            value = new Date(value).toLocaleDateString();
          }
          if (props.columns[j].dataType === "date/time" && value) {
            value =
              new Date(value).toLocaleDateString() +
              " " +
              new Date(value).toLocaleTimeString();
          }
          if (
            value &&
            value
              .toString()
              .toLocaleLowerCase()
              .indexOf(text.toLocaleLowerCase()) !== -1
          ) {
            acceptItems.push(rows[i]);
            break;
          }
        }
      }
    }
    return acceptItems;
  };

  const doSearch = (value: string) => {
    setHoldOn(true);
    setActiveFilterValue(value);

    setTimeout(() => {
      try {
        setHoldOn(false);
      } catch (e) {}
    }, 200);
  };

  const filteredItems = filterItems(props.rowData, activeFilterValue);

  let rowsFinal = filteredItems.slice(
    pagination.page * pagination.perPage,
    (pagination.page + 1) * pagination.perPage
  );

  return (
    <div>
      <div className="search-row">
        <Paginator
          max={filteredItems.length}
          emitPage={(page: number, perPage: number) => {
            setPagination({ page, perPage, paginating: true });
            setTimeout(() => {
              setPagination({ page, perPage, paginating: false });
            }, 200);
          }}
        />
        <div style={{ display: "flex", gap: "1em" }}>
          <Input
            placeholder="Filter text"
            onChange={(e: any, data: InputOnChangeData) => {
              setFilterValue(data.value);
            }}
          />
          <TeamsButton
            primary
            text="Search"
            onClick={() => {
              doSearch(filterValue);
            }}
          />
        </div>
      </div>
      <DataGrid
        items={rowsFinal}
        columns={columns}
        sortable
        selectionMode="multiselect"
        getRowId={(item) => JSON.stringify(item)}
        focusMode="composite"
      >
        <DataGridHeader>
          <DataGridRow
            selectionCell={{
              checkboxIndicator: { "aria-label": "Select all rows" },
            }}
          >
            {({ renderHeaderCell }) => (
              <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
            )}
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody<any>>
          {({ item, rowId }) => (
            <DataGridRow<any>
              key={rowId}
              selectionCell={{
                checkboxIndicator: { "aria-label": "Select row" },
              }}
            >
              {({ renderCell }) => (
                <DataGridCell className="data-grid-cell-styler">
                  {renderCell(item)}
                </DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
      {rowsFinal.length === 0 && (
        <div className="no-data-on-list">No data to display.</div>
      )}
    </div>
  );
};

export default TeamsDataGrid;
