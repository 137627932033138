import * as microsoftTeams from "@microsoft/teams-js";
import { RemoteLog } from "./DataSupportAPI";

const getTokenOnBehalf = async (idtoken: string) => {
  let result: any = await fetch("./auth/GetUserAccessToken", {
    headers: { Authorization: `Bearer ${idtoken}` },
  });

  if (result.status < 300) {
    return await result.text();
  }

  if (result.status === 401) {
    AuthenticationHelper.handleTokenExpired();
  }

  return undefined;
};

// 1. Get auth token
// Ask Teams to get us a token from AAD
const getClientSideToken = () => {
  return new Promise((resolve, reject) => {
    microsoftTeams.authentication
      .getAuthToken()
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(undefined);
      });
  });
};

const handleTokenExpired = () => {
  localStorage.clear();
  window.location.reload();
};

const initTeams = async () => {
  try {
    (window as any)["microsoftTeams"] = microsoftTeams;
    await microsoftTeams.app.initialize([]);
    RemoteLog("DataSupportAPI", "successfully inited MS Teams Client");
    return true;
  } catch (e) {
    return false;
  }
};

const getTeamsToken = async () => {
  let teamsInit = await initTeams();
  if (teamsInit) {
    try {
      let token = await getClientSideToken();
      return token as string;
    } catch (e) {
      return undefined;
    }
  } else {
    return null;
  }
};

const deleteAllCookies = () => {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};

const AuthenticationHelper = {
  getTeamsToken,
  deleteAllCookies,
  handleTokenExpired,
  getTokenOnBehalf,
  initTeams,
};

export default AuthenticationHelper;
