import { useEffect, useState } from "react";
import "./SharepointDocumentsTabPage.scss";
import {
  ISharepointSite,
  ISharepointSiteItem,
} from "../../Models/ISharepointSite";
import GraphApi from "../../Utils/GraphApi";
import { GlobalState } from "../../Redux/RootReducer";
import { useSelector } from "react-redux";
import { Tools } from "../../Utils/Tools";
import { ISharepointListItem } from "../../Models/ISharepointList";
import { Link, ProgressIndicator, Spinner } from "@fluentui/react";
import { ISharepointListContentItem } from "../../Models/ISharepointListContent";
import FluentDataDable from "../../Components/FluentDataDable/FluentDataDable";
import TeamsTable from "../../Components/TeamsTable/TeamsTable";
import TeamsProgressBar from "../../Components/TeamsProgressBar/TeamsProgressBar";

export const nameFromWebUrl = (webUrl: string) => {
  let url = new URL(webUrl);
  let path = url.pathname.split("/");
  return decodeURI(path[path.length - 1]);
};

const SharepointDocumentsTabPage = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [loadingBarPercent, setLoadingBarPercent] = useState<number>(0);
  const [loadingBarPercent2, setLoadingBarPercent2] = useState<number>(0);

  const [sites, setSites] = useState<ISharepointSiteItem[]>([]);
  const [items, setItems] = useState<ISharepointListContentItem[]>([]);
  const token: string | undefined = useSelector(
    (state: GlobalState) => state.generic.token
  );

  const getDocumentsFromAllSharepointsSources = async () => {
    setLoading(true);

    // all sites the user is allowed to access
    let sites: ISharepointSiteItem[] = await GraphApi.SharepointGetAllSites(
      token
    );
    setSites(sites);

    // get all the lists from all the sites
    let listResult: ISharepointListItem[] = [];
    for (let i = 0; i < sites.length; i++) {
      setLoadingBarPercent(i / sites.length);
      let result: ISharepointListItem[] =
        await GraphApi.SharepointGetAllListsForSite(token, sites[i].id);

      listResult = listResult.concat(
        result.filter(
          (x) =>
            x.list.template === "documentLibrary" && x.list.hidden === false
        )
      );
    }

    setLoadingBarPercent(1);
    // get all files from each document library
    let items: ISharepointListContentItem[] = [];
    for (let i = 0; i < listResult.length; i++) {
      setLoadingBarPercent2(i / listResult.length);
      let result: ISharepointListContentItem[] =
        await GraphApi.SharepointGetAllItemsForList(
          token,
          listResult[i].parentReference.siteId,
          listResult[i].id
        );

      items = items.concat(result);
    }

    setItems(
      items.filter(
        (x: ISharepointListContentItem) => x.contentType.name !== "Folder"
      )
    );

    setLoading(false);
  };

  useEffect(() => {
    getDocumentsFromAllSharepointsSources();
  }, []);

  const processDataItems = () => {
    let processedItems: any = [];
    for (let i = 0; i < items.length; i++) {
      let ctype: string = items[i].contentType.name.toLocaleLowerCase();
      if (ctype.includes("document") || true) {
        let item = items[i];
        let newItem: any = {
          ...item,
        };
        newItem["siteName"] = sites.find(
          (x) => x.id === item.parentReference.siteId
        )?.displayName;
        newItem["plainName"] = nameFromWebUrl(item.webUrl);
        processedItems.push(newItem);
      }
    }
    return processedItems;
  };

  const itemsProcessed = processDataItems();

  return (
    <div>
      {loading && (
        <div className="loading-bar-center">
          <TeamsProgressBar
            label={"Getting all lists..."}
            percentComplete={loadingBarPercent}
          />
          <br />
          <TeamsProgressBar
            label={"Getting all files..."}
            percentComplete={loadingBarPercent2}
          />
        </div>
      )}

      {!loading && (
        <TeamsTable
          columns={[
            {
              name: "Site Name",
              dataType: "string",
              fieldName: "siteName",
              size: "medium",
            },
            {
              name: "Name",
              dataType: "string",
              fieldName: "plainName",
              size: "medium",
            },
            {
              name: "Content Type",
              dataType: "string",
              fieldName: "contentType.name",
              size: "medium",
            },
            {
              name: "Web Link",
              dataType: "link",
              fieldName: "webUrl",
              size: "medium",
            },
            {
              name: "Created By",
              dataType: "string",
              fieldName: "createdBy.user.displayName",
              size: "medium",
            },
            {
              name: "Created On",
              dataType: "date/time",
              fieldName: "createdDateTime",
              size: "medium",
            },
            {
              name: "Updated By",
              dataType: "string",
              fieldName: "lastModifiedBy.user.displayName",
              size: "medium",
            },
            {
              name: "Updated On",
              dataType: "date/time",
              fieldName: "lastModifiedDateTime",
              size: "medium",
            },
          ]}
          rowData={itemsProcessed}
        />
      )}
    </div>
  );
};

export default SharepointDocumentsTabPage;
