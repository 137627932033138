import "./CallToActionButton.scss";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import SmartModal, {
  DismissModal,
  ISmartModalButtons,
  SummonModal,
} from "../SmartModal/SmartModal";
import { Label } from "@fluentui/react-components";

export interface ICallToActionButtonProps {
  targetElementId: number;
  currentCallToAction: string;
  disabled?: boolean;
  onActionClick: (action: string) => void;
}

const CallToActionButton = (props: ICallToActionButtonProps) => {
  let splAction: string[] = (props.currentCallToAction ?? "")
    .split(",")
    .filter((x) => x);

  let buttons: ISmartModalButtons[] = [];

  splAction.forEach((x: string) => {
    buttons.push({
      text: x,
      onClick: () => {
        DismissModal("call-to-action-modal-for-item-" + props.targetElementId);
        props.onActionClick(x);
      },
    });
  });

  buttons.push({
    text: "Close",
    onClick: () => {
      DismissModal("call-to-action-modal-for-item-" + props.targetElementId);
    },
  });

  if (props.disabled) {
    splAction = [];
  }

  return (
    <div className="cta-main-wrap">
      {splAction.length === 0 && <Label>{"No action required"}</Label>}
      {splAction.length > 0 && (
        <CustomTooltip
          isButton
          disabled={splAction.length === 0}
          iconName="Send"
          content={<div>Take action</div>}
          onClick={() => {
            SummonModal(
              "call-to-action-modal-for-item-" + props.targetElementId
            );
          }}
        />
      )}
      <SmartModal
        modalUniqueId={"call-to-action-modal-for-item-" + props.targetElementId}
        title="Perform the requested action"
        content={
          <Label>
            You have been requested to perform one of the following action(s):{" "}
            <br />
            <strong>{props.currentCallToAction}</strong>
            <br />
            <br />
            If you acknowledge this request, please click the action below.
          </Label>
        }
        buttons={buttons}
      />
    </div>
  );
};

export default CallToActionButton;
