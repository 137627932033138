import { IUser } from "../Models/IUser";
import { IAPIResponse } from "../Services/AjaxService";
import ApiService from "../Services/ApiService";

export interface IAuthorLabel {
  CreatedBy: number;
  CreatedByEntity: string;
}
export interface IAuthorLabelResult {
  CreatedBy: number;
  CreatedByEntity: string;
  resultUser: IUser;
}

export const InjectAuthorData = async (genericModelList: IAuthorLabel[]) => {
  let singularToProcess: IAuthorLabel[] = [];

  // extract unique creators
  for (let i = 0; i < genericModelList.length; i++) {
    let item = genericModelList[i];
    if (
      singularToProcess.findIndex(
        (x) =>
          x.CreatedBy === item.CreatedBy &&
          x.CreatedByEntity === item.CreatedByEntity
      ) === -1
    ) {
      singularToProcess.push({
        CreatedBy: item.CreatedBy,
        CreatedByEntity: item.CreatedByEntity,
      });
    }
  }

  // for each creator collect the user data
  let processedResults: IAuthorLabelResult[] = [];
  for (let i = 0; i < singularToProcess.length; i++) {
    let item = singularToProcess[i];
    let response: IAPIResponse =
      await ApiService.GenericController.InfoOnAuthor(
        item.CreatedByEntity,
        item.CreatedBy
      );
    if (response.error === null) {
      processedResults.push({ ...item, resultUser: response.parsed });
    }
  }

  // aggregate the results
  genericModelList.forEach((item: any) => {
    let targetAuthor: IAuthorLabelResult | undefined = processedResults.find(
      (x: IAuthorLabelResult) =>
        x.CreatedBy === item.CreatedBy &&
        x.CreatedByEntity === item.CreatedByEntity
    );
    item.CreatedByEmail = targetAuthor
      ? targetAuthor.resultUser.UserEmail
      : "Unknown";
  });

  return genericModelList;
};

export const DownloadFileWithName = (path: string, fileName: string) => {
  let a = document.createElement("a");
  a.href = path;
  a.download = fileName;
  a.click();
};

export const DownloadAzureBlob = async (
  containerName: string,
  blobReference: string
) => {
  let response: IAPIResponse = await ApiService.FileController.GetSASString(
    containerName,
    blobReference
  );

  if (response.error === null) {
    let sas: string = response.parsed.TokenSAS;
    if (sas) {
      let fileSpl: string[] = blobReference.split("____");
      let fileName: string = fileSpl[3];
      debugger;
      let fileBlob: IAPIResponse = await ApiService.FileController.DownloadBlob(
        sas
      );

      if (fileBlob.error === null) {
        let blob = await fileBlob.raw.blob();
        let file = new Blob([blob], { type: "application/octet-stream" });
        DownloadFileWithName(URL.createObjectURL(file), fileName);
      }
    }
  }

  return null;
};

export const RemoteLog = (logLocation: string, logContent: string) => {
  //ApiService.GenericController.WriteLog(logLocation, logContent);
};
