import React, { useEffect, useState } from "react";
import "./App.scss";
import AppLayout from "./AppLayout";
import { Providers, ProviderState } from "@microsoft/mgt-element";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import AuthenticationHelper from "./Utils/AuthenticationHelper";
import MicrosoftLogin from "./Components/MicrosoftLogin/MicrosoftLogin";
import { useDispatch, useSelector } from "react-redux";
import { GenericAction, GenericActions } from "./Redux/Generic/GenericAction";
import AppTeamsWrapper from "./AppTeamsWrapper";
import { useNavigate } from "react-router-dom";
import AppPreloader from "./AppPreloader";
import { GlobalState } from "./Redux/RootReducer";
import { AjaxService } from "./Services/AjaxService";
import { DismissModal } from "./Components/SmartModal/SmartModal";
import { RemoteLog } from "./Utils/DataSupportAPI";
import TeamsSpinner from "./Components/TeamsSpinner/TeamsSpinner";
import { Label } from "@fluentui/react-components";

Providers.globalProvider = new Msal2Provider({
  clientId: process.env.REACT_APP_MSAL_CLIENT_ID ?? "",
  authority: process.env.REACT_APP_MSAL_AUTHORITY ?? "",
  scopes: (process.env.REACT_APP_MSAL_SCOPES ?? "").split(","),
});

export const IsBrowserMode = () => {
  return localStorage.getItem("isBrowserMode") === "true";
};

export function useIsSignedIn(): [boolean] {
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    const updateState = () => {
      const provider = Providers.globalProvider;
      setIsSignedIn(provider && provider.state === ProviderState.SignedIn);
    };

    Providers.onProviderUpdated(updateState);
    updateState();

    return () => {
      Providers.removeProviderUpdatedListener(updateState);
    };
  }, []);

  return [isSignedIn];
}

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSignedIn] = useIsSignedIn();
  const [loading, setLoading] = useState<boolean>(true);
  const [browserMode, setBrowserMode] = useState<boolean>(false);

  const token: string | undefined = useSelector(
    (state: GlobalState) => state.generic.token
  );
  const myTeamsAssistantToken: string | undefined = useSelector(
    (state: GlobalState) => state.generic.myTeamsAssistantToken
  );
  const getTeamsToken = async (idtoken: string | undefined | null) => {
    if (idtoken) {
      RemoteLog("AppPreloader", "loaded ID Token");
      let onBehalfToken: string = await AuthenticationHelper.getTokenOnBehalf(
        idtoken
      );
      RemoteLog("App", "successfully got onBehalfToken");
      dispatch(GenericActions.setAuthToken(onBehalfToken));
    } else {
      RemoteLog("AppPreloader", "app is browser mode");
      setBrowserMode(true);
    }

    setLoading(false);
  };

  const msalGetToken = async () => {
    if (Providers.globalProvider.state === ProviderState.SignedIn) {
      const token = await Providers.globalProvider.getAccessToken();
      dispatch(GenericActions.setAuthToken(token));
      localStorage.setItem("isBrowserMode", "true");
    }
  };

  useEffect(() => {
    if (isSignedIn) {
      msalGetToken();
    }
  }, [isSignedIn]);

  const doLogOut = () => {
    dispatch(GenericActions.setUser(undefined));
    dispatch(GenericActions.setAuthToken(undefined));
    dispatch(GenericActions.setMTAToken(undefined));
    localStorage.clear();

    setTimeout(() => {
      window.location.reload();
    }, 500);
    navigate("/");
  };

  const initHandlersAjaxCodes = () => {
    AjaxService.CustomResponseCodeHandlers.push({
      code: 401,
      action: (responseStatus: number) => {
        doLogOut();
      },
    });
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Escape") {
      DismissModal("*");
    }
  };

  const onStart = async () => {
    let idtoken = await AuthenticationHelper.getTeamsToken();

    if (myTeamsAssistantToken === undefined) {
      getTeamsToken(idtoken);
    } else {
      RemoteLog("AppPreloader", "app is quickstarting");
      setBrowserMode(!idtoken);
      setLoading(false);
    }

    initHandlersAjaxCodes();
  };

  useEffect(() => {
    onStart();
    document.body.addEventListener("dologout", doLogOut);
    document.body.addEventListener("keyup", handleKeyPress);
    return () => {
      document.body.removeEventListener("keyup", handleKeyPress);
      document.body.removeEventListener("dologout", doLogOut);
    };
  }, []);

  return (
    <div>
      {loading && (
        <div className="loading-main-wrapper">
          <TeamsSpinner />
        </div>
      )}
      {!loading && browserMode && !token && (
        <div className="not-on-teams">
          <Label style={{ fontSize: "1.5em" }}>
            You are not using Microsoft Teams. You will need to login to
            continue.
          </Label>

          <div className="login-button-wrap">
            <MicrosoftLogin />
          </div>
        </div>
      )}

      {token && <AppPreloader />}
    </div>
  );
}

export default App;
