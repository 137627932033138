import { useEffect, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";

import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  Theme,
} from "@fluentui/react-components";
import AppLayout from "./AppLayout";
import { RemoteLog } from "./Utils/DataSupportAPI";
import App from "./App";
import AuthenticationHelper from "./Utils/AuthenticationHelper";

enum TeamsTheme {
  HighContrast = "teamsHighContrastTheme",
  Dark = "teamsDarkTheme",
  Default = "teamsTheme",
}

// Possible values for theme: 'default', 'light', 'dark' and 'contrast'
function initTeamsTheme(theme: string | undefined): TeamsTheme {
  switch (theme) {
    case "dark":
      return TeamsTheme.Dark;
    case "contrast":
      return TeamsTheme.HighContrast;
    default:
      return TeamsTheme.Default;
  }
}

function AppTeamsWrapper() {
  const [appContext, setAppContext] = useState<microsoftTeams.app.Context>();
  const [activeTheme, setActiveTheme] = useState<Theme>(teamsLightTheme);
  const [appAppearance, setAppAppearance] = useState<TeamsTheme>(
    TeamsTheme.Default
  );

  const onStart = async () => {
    /**
     * With the context properties in hand, your app has a solid understanding of what's happening around it in Teams.
     * https://docs.microsoft.com/en-us/javascript/api/@microsoft/teams-js/context?view=msteams-client-js-latest&preserve-view=true
     **/
    microsoftTeams.app.getContext().then((context) => {
      setAppContext(context);
      setAppAppearance(initTeamsTheme(context.app.theme));

      /**
       * Tells Microsoft Teams platform that we are done saving our settings. Microsoft Teams waits
       * for the app to call this API before it dismisses the dialog. If the wait times out, you will
       * see an error indicating that the configuration settings could not be saved.
       **/
      microsoftTeams.app.notifySuccess();
    });

    /**
     * Theme change handler
     * https://docs.microsoft.com/en-us/javascript/api/@microsoft/teams-js/microsoftteams?view=msteams-client-js-latest#registerOnThemeChangeHandler__theme__string_____void_
     **/
    microsoftTeams.app.registerOnThemeChangeHandler((theme) => {
      setAppAppearance(initTeamsTheme(theme));
    });
  };

  useEffect(() => {
    onStart();
  }, []);

  useEffect(() => {
    switch (appAppearance) {
      case TeamsTheme.Dark:
        setActiveTheme(teamsDarkTheme);
        break;
      case TeamsTheme.HighContrast:
        setActiveTheme(teamsHighContrastTheme);
        break;
      default:
        setActiveTheme(teamsLightTheme);
        break;
    }
  }, [appAppearance]);
  return (
    <FluentProvider theme={activeTheme}>
      <div className={"app-background-dynamic-loader-" + appAppearance}>
        <AppLayout />
      </div>
    </FluentProvider>
  );
}

export default AppTeamsWrapper;
