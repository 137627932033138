import SessionController from "./Controllers/SessionController";
import AssistantTaskController from "./Controllers/AssistantTaskController";
import BusinessItemController from "./Controllers/BusinessItemController";
import FileController from "./Controllers/FileController";
import GraphController from "./Controllers/GraphController";
import NotificationController from "./Controllers/NotificationController";
import GenericController from "./Controllers/GenericController";

export const BuildParameters = (
  baseUrl: string,
  parameters: [string, any][]
) => {
  let params: string[] = [];
  parameters.forEach((x: [string, any]) => {
    if (x[1]) {
      params.push(x[0] + "=" + x[1]);
    }
  });

  if (baseUrl.includes("?")) {
    return baseUrl + "&" + params.join("&");
  } else {
    return baseUrl + "?" + params.join("&");
  }
};

const ApiService = {
  SessionController,
  GenericController,
  BusinessItemController,
  AssistantTaskController,
  NotificationController,
  GraphController,
  FileController,
};

(window as any)["ApiService"] = ApiService;

export default ApiService;
