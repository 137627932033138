import { useEffect, useState } from "react";
import "./ChatGPTBox.scss";
import ApiService from "../../Services/ApiService";
import TeamsButton from "../TeamsButton/TeamsButton";
import { Tools } from "../../Utils/Tools";
import Loadable from "../Loadable/Loadable";
import { IChatGTPResponse, ICitation } from "../../Models/IChatGTPResponse";
import { Link } from "@fluentui/react";
import { IAPIResponse } from "../../Services/AjaxService";

import AI_BOT from "../../Assets/ai-bot.png";
import { Label } from "@fluentui/react-components";
import TeamsTextField from "../TeamsTextField/TeamsTextField";

export interface IChatGPTBoxProps {}
export interface IAnswerBoxProps {
  response: IChatGTPResponse;
}

const AnswerBox = (props: IAnswerBoxProps) => {
  const [chatAnswer, setChatAnswer] = useState<IChatGTPResponse | undefined>(
    undefined
  );

  const [showMore, setShowMore] = useState<boolean>(false);

  useEffect(() => {
    setChatAnswer(props.response);
  }, [props.response]);

  return (
    <div>
      {chatAnswer && (
        <div className="answer-slot-wrap">
          <div className="answer-slot-question">
            {chatAnswer.relatedQuestion}
          </div>
          <div className="answer-slot-response">
            <img src={AI_BOT} alt="AI Bot" className="image-ai-bot" />
            {chatAnswer.ChatMessage}

            {chatAnswer.citations.length > 0 && (
              <div>
                <hr />
                {!showMore && (
                  <Link
                    to=""
                    onClick={() => {
                      setShowMore(true);
                    }}
                  >
                    Show related citations ({chatAnswer.citations.length})
                  </Link>
                )}

                {showMore && (
                  <div>
                    <Link
                      to=""
                      onClick={() => {
                        setShowMore(false);
                      }}
                    >
                      Hide citations
                    </Link>
                    <div>
                      {(chatAnswer.citations ?? []).map(
                        (y: ICitation, j: number) => {
                          return (
                            <div
                              key={j}
                              className="answer-slot-response-citation-wrap"
                            >
                              <div className="answer-slot-response-citation-title">
                                <div> {y.title}</div>
                                <small>
                                  From: {y.filepath.split("____")[3]}
                                </small>
                              </div>
                              <div className="answer-slot-response-citation-content">
                                {y.content}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

var chatAnswersClone: IChatGTPResponse[] = [];

const ChatGPTBox = (props: IChatGPTBoxProps) => {
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [chatAnswers, setChatAnswers_] = useState<IChatGTPResponse[]>([]);
  const [query, setQuery] = useState<string>("");

  const setChatAnswers = (data: IChatGTPResponse[]) => {
    setChatAnswers_(data);
    chatAnswersClone = [...data];
  };

  const addChatAnswer = (query: string) => {
    debugger;
    let answer: IChatGTPResponse = {
      ChatMessage: "Thinking...",
      citations: [],
      relatedQuestion: query,
    };
    let newAnswers = [...chatAnswers];
    newAnswers.push(answer);
    setChatAnswers(newAnswers);

    setTimeout(() => {
      let objDiv: any = document.getElementById("answer-div");
      objDiv.scrollTop = objDiv.scrollHeight;
    }, 200);
  };

  const updateAnswerItem = (answer: IChatGTPResponse) => {
    let answers = [...chatAnswersClone];
    answers[answers.length - 1] = { ...answer, relatedQuestion: query };
    setChatAnswers(answers);

    setTimeout(() => {
      let objDiv: any = document.getElementById("answer-div");
      objDiv.scrollTop = objDiv.scrollHeight;
    }, 200);
  };

  const AskChatGPT = (query: string) => {
    setLoading(true);
    addChatAnswer(query);
    ApiService.GenericController.AskChatGPT(query, (response: IAPIResponse) => {
      if (response.error === null) {
        updateAnswerItem(
          response.parsed.length > 0
            ? response.parsed[0]
            : {
                ChatMessage: "No answer found",
                citations: [],
                relatedQuestion: query,
              }
        );
      }
      setLoading(false);
    });
  };

  useEffect(() => {}, []);

  return (
    <div
      className={
        "chat-positioner " + (!isChatOpen ? "chat-closed-animator" : "")
      }
    >
      <div
        className={
          "dynamic-teams-theme-background-setter chat-handle " +
          (isChatOpen ? "chat-handle-open" : "")
        }
        onClick={() => {
          if (!isChatOpen) {
            setIsChatOpen(true);
          }
        }}
      >
        {!isChatOpen && (
          <div>
            <img src={AI_BOT} alt="AI Bot" className="image-ai-bot-small" />
          </div>
        )}
        <div>
          <div className="open-chat-title-bar">
            <div className="inline-icon">
              <img src={AI_BOT} alt="AI Bot" className="image-ai-bot" />
              <Label style={{ fontSize: "1.2em" }}>
                Ask AI about your documents
              </Label>
            </div>

            <TeamsButton
              text="Close"
              primary
              onClick={() => {
                setIsChatOpen(false);
              }}
            />
          </div>
          {isChatOpen && (
            <div className="open-chat-chat-content">
              <div
                className="open-chat-answers-box dynamic-teams-theme-background-setter"
                id="answer-div"
              >
                {chatAnswers.map((x: IChatGTPResponse, i: number) => {
                  return <AnswerBox key={i} response={x} />;
                })}
              </div>
              <Loadable
                noPadding
                isLoading={loading}
                content={
                  <TeamsTextField
                    style={{ width: "90%" }}
                    placeholder="Ask a question"
                    onChange={(text: string) => {
                      setQuery(text);
                    }}
                    onKeyDown={(e: any) => {
                      if (e.key === "Enter" && query.length > 0) {
                        AskChatGPT(query);
                      }
                    }}
                  />
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatGPTBox;
