import { IAppDictionary } from "./Models/IAppDictionary";
import AzureBlobTabPage from "./Pages/AzureBlobTabPage/AzureBlobTabPage";
import BusinessItemsTabPage from "./Pages/BusinessItemsTabPage/BusinessItemsTabPage";
import DocumentsAggregatedViewPage from "./Pages/DocumentsAggregatedViewPage/DocumentsAggregatedViewPage";
import NotificationsTabPage from "./Pages/NotificationsTabPage/NotificationsTabPage";
import ScopesChartView from "./Pages/ScopesChartView/ScopesChartView";
import SharepointDocumentsTabPage from "./Pages/SharepointDocumentsTabPage/SharepointDocumentsTabPage";
import TasksTabPage from "./Pages/TasksTabPage/TasksTabPage";

export const APP_ROUTE_NAMES: any = {
  DASHBOARD: "/dashboard",
  DASHBOARD_SCOPES: "/dashboard/scopes",
  NOTIFICATIONS: "/notifications",
  NOTIFICATIONS_INNER: "/notifications/items",
  APPROVAL: "/tasks",
  APPROVAL_INNER: "/tasks/items",
  BUSINESS_ITEMS: "/business-items",
  BUSINESS_ITEMS_INNER: "/business-items/items",
  DOCUMENTAL: "/documents",
  DOCUMENTAL_SHAREPOINT: "/documents/sharepoint",
  DOCUMENTAL_AZUREBLOB: "/documents/azureblob",
  DOCUMENTAL_AGGREGATE: "/documents/items",
};

export interface IAppRouteItem {
  path: string;
  routeName: string;
  search?: string;
  element: JSX.Element;
  invisibleTab?: boolean;
}

export interface IAppTabs {
  tabName: string;
  tabPath: string;
  tabChildRoutes: IAppRouteItem[];
}

const AppTabs: IAppTabs[] = [
  {
    tabName: "Dashboard",
    tabPath: APP_ROUTE_NAMES.DASHBOARD,
    tabChildRoutes: [],
  },
  {
    tabName: "Notifications",
    tabPath: APP_ROUTE_NAMES.NOTIFICATIONS,
    tabChildRoutes: [
      {
        path: APP_ROUTE_NAMES.NOTIFICATIONS_INNER,
        routeName: "Items",
        element: <NotificationsTabPage />,
        invisibleTab: true,
      },
    ],
  },
  {
    tabName: "Approval",
    tabPath: APP_ROUTE_NAMES.APPROVAL,
    tabChildRoutes: [
      {
        path: APP_ROUTE_NAMES.APPROVAL_INNER,
        routeName: "Items",
        element: <TasksTabPage onlyCta />,
        invisibleTab: true,
      },
    ],
  } /*
  {
    tabName: "Business Items",
    tabPath: APP_ROUTE_NAMES.BUSINESS_ITEMS,
    tabChildRoutes: [
      {
        path: APP_ROUTE_NAMES.BUSINESS_ITEMS_INNER,
        routeName: "Items",
        element: <BusinessItemsTabPage />,
        invisibleTab: true,
      },
    ],
  },*/,
  {
    tabName: "Documental",
    tabPath: APP_ROUTE_NAMES.DOCUMENTAL,
    tabChildRoutes: [
      {
        path: APP_ROUTE_NAMES.DOCUMENTAL_AGGREGATE,
        routeName: "Items",
        element: <DocumentsAggregatedViewPage />,
        invisibleTab: true,
      },
      /*{
        path: APP_ROUTE_NAMES.DOCUMENTAL_SHAREPOINT,
        routeName: "Sharepoint",
        element: <SharepointDocumentsTabPage />,
      },
      {
        path: APP_ROUTE_NAMES.DOCUMENTAL_AZUREBLOB,
        routeName: "Azure Blob Storage",
        element: <AzureBlobTabPage />,
      },*/
    ],
  },
];

export var ProcessedAppRoutes: IAppTabs[] = [];

export const InitDynamicAppRoutes = (appDictionaries: IAppDictionary[]) => {
  if (ProcessedAppRoutes.length === 0) {
    let baseTabs: IAppTabs[] = [...AppTabs];

    let scopeDictionary: IAppDictionary[] = appDictionaries.filter(
      (x: IAppDictionary) => x.DictionaryType === "scope-option"
    );

    scopeDictionary.forEach((x: IAppDictionary, i: number) => {
      baseTabs[0].tabChildRoutes.push({
        path: APP_ROUTE_NAMES.DASHBOARD_SCOPES,
        search: "scopeTarget=" + x.DictionaryDisplayName,
        routeName: x.DictionaryDisplayName,
        element: <ScopesChartView />,
      });
    });

    ProcessedAppRoutes = baseTabs;
  }
  return ProcessedAppRoutes;
};
