import {
  Link,
  MarqueeSelection,
  PrimaryButton,
  SearchBox,
  Spinner,
} from "@fluentui/react";
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  Selection,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import { useEffect, useState } from "react";

import "./FluentDataDable.scss";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import TeamsButton from "../TeamsButton/TeamsButton";
import TeamsSpinner from "../TeamsSpinner/TeamsSpinner";
import Paginator from "../Paginator/Paginator";

export interface IFluentDataDableProps {
  onLinkClick: (item: any, link: string) => void;
  rowData: any[];
  columns: any[];
  actionColumnName?: string;
  isLoading?: boolean;
  disablePagination?: boolean;
  actionColumn?: (item: any) => JSX.Element;
  extraInnerProps?: IGenericListProps;
  extraInHeaderItems?: (JSX.Element | null)[];
  onPagination?: (page: number, perPage: number) => void;
  addPaddingColumn?: boolean;
}

const extractItemValueOnPath = (item: any, path: string) => {
  try {
    let dotPath: string[] = path.split(".");
    for (let i = 0; i < dotPath.length; i++) {
      item = item[dotPath[i]];
    }
    return item;
  } catch (e) {
    return null;
  }
};

export interface IGenericListProps {
  onSelectionChanged?: (items: any[]) => void;
  onItemInvoked?: (item: any) => void;
  allowFilter?: boolean;
  searchText?: string;
  emitSearchedText?: (text: string) => void;
  columns?: any[];
  items?: any[];
  selectionMode?: SelectionMode;
  initialSelection?: (item: any) => boolean;
  iconFromField?: string;
  extraHeaderItems?: JSX.Element[];
}

export const fromIdPropertyTypeToType = (propType: number) => {
  switch (propType) {
    case 1:
      return "string";
    case 2:
      return "numeric";
    case 3:
      return "date";
    case 4:
      return "date/time";
    case 5:
      return "dropDrown";
    case 6:
      return "boolean";
    default:
      return "string";
  }
};

export interface IBasicTableProps {
  rows: any[];
  columns: any[];
  disablePagination?: boolean;
  extraHeaderItems?: JSX.Element[];
  extraInHeaderItems?: (JSX.Element | null)[];
  onPagination: (page: number, perPage: number) => void;
  addPaddingColumn?: boolean;
  onLinkClick: (item: any, link: string) => void;
}

const BasicTable = (props: IBasicTableProps) => {
  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [filterValue, setFilterValue] = useState<string>("");
  const [activeFilterValue, setActiveFilterValue] = useState<string>("");
  const [holdOn, setHoldOn] = useState<boolean>(false);
  const [sortOnColumn, setSortOnColumn] = useState<number>(0);
  const DEFAULT_INITIAL_PAGES = 10;
  const [pagination, setPagination] = useState<{
    page: number;
    perPage: number;
    paginating: boolean;
  }>({
    page: 0,
    perPage: props.disablePagination ? 10000 : DEFAULT_INITIAL_PAGES,
    paginating: false,
  });

  useEffect(() => {
    setRows(props.rows);
    setColumns(props.columns);
  }, [props.rows, props.columns]);

  useEffect(() => {
    props.onPagination(pagination.page, pagination.perPage);
  }, [pagination]);

  const filterItems = (rows: any, text: string) => {
    if (text === "") {
      return rows;
    }
    let acceptItems: any[] = [];

    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < columns.length; j++) {
        let field = columns[j].fieldName;
        if (field) {
          let data = rows[i][field];
          let value = data;
          if (data && data.value !== undefined) {
            value = data.value;
          }
          if (columns[j].dataType === "date" && value) {
            value = new Date(value).toLocaleDateString();
          }
          if (columns[j].dataType === "date/time" && value) {
            value =
              new Date(value).toLocaleDateString() +
              " " +
              new Date(value).toLocaleTimeString();
          }
          if (
            value &&
            value
              .toString()
              .toLocaleLowerCase()
              .indexOf(text.toLocaleLowerCase()) !== -1
          ) {
            acceptItems.push(rows[i]);
            break;
          }
        }
      }
    }
    return acceptItems;
  };

  const doSearch = (value: string) => {
    setHoldOn(true);
    setActiveFilterValue(value);

    setTimeout(() => {
      try {
        setHoldOn(false);
      } catch (e) {}
    }, 200);
  };

  const extractItemByField = (row: any, columns: any[], index: number) => {
    let col = columns.length > index ? columns[index] : null;
    if (col && row) {
      let fieldName = col.fieldName;
      let it = fieldName ? extractItemValueOnPath(row, fieldName) : "";
      if (col.onRender) {
        return <span title={it}>{col.onRender(row)}</span>;
      }
      if (fieldName) {
        let dataType = col.dataType;
        if (dataType && dataType === "date/time") {
          if (it) {
            let str = new Date(it).toLocaleDateString();
            let time = new Date(it).toLocaleTimeString();

            return (
              <span title={str + " " + time}>
                <div style={{ display: "inline-block" }}>{str}</div>
                <div
                  style={{
                    fontSize: "0.8em",
                    display: "inline-block",
                    marginLeft: "0.3em",
                  }}
                >
                  {time}
                </div>
              </span>
            );
          } else {
            return <span></span>;
          }
        } else if (dataType && dataType === "date") {
          let str = new Date(it).toLocaleDateString();
          return <span title={str}>{str}</span>;
        } else if (dataType && dataType === "link") {
          return (
            <Link
              target={"_blank"}
              to={"#"}
              onClick={() => {
                props.onLinkClick(row, it);
              }}
            >
              Link
            </Link>
          );
        } else {
          return <span title={it}>{it}</span>;
        }
      }
    }
    return <div></div>;
  };

  const getColumnSortingElement = (index: number) => {
    // skip 0 index
    index = index + 1;

    if (sortOnColumn !== 0) {
      if (sortOnColumn === index) {
        return (
          <CustomTooltip
            isButton
            content="Sort on this column"
            iconName="ChevronDownMed"
            onClick={() => {}}
          />
        );
      } else if (sortOnColumn === -index) {
        return (
          <CustomTooltip
            isButton
            content="Sort on this column"
            iconName="ChevronUpMed"
            onClick={() => {}}
          />
        );
      } else {
        return (
          <CustomTooltip
            isButton
            content="Sort on this column"
            iconName="ChevronUnfold10"
            onClick={() => {}}
          />
        );
      }
    } else {
      return (
        <CustomTooltip
          isButton
          content="Sort on this column"
          iconName="ChevronUnfold10"
          onClick={() => {}}
        />
      );
    }
  };

  const filteredItems = filterItems(rows, activeFilterValue);

  const sortItems = (items: any[]) => {
    let sortOnColumn_ = sortOnColumn + 0; // duplicate
    let reverseSort = false;
    if (sortOnColumn_ < 0) {
      reverseSort = true;
      sortOnColumn_ = sortOnColumn_ * -1;
    }

    let finalIndex = sortOnColumn_ - 1;
    let targetColumn =
      columns.length > finalIndex && finalIndex >= 0
        ? columns[finalIndex]
        : null;

    if (targetColumn) {
      let targetField = targetColumn.fieldName ?? "";
      let sorted = [...items].sort((a: any, b: any) => {
        if (a[targetField] < b[targetField]) {
          return -1;
        }
        if (a[targetField] > b[targetField]) {
          return 1;
        }
        return 0;
      });

      if (reverseSort) {
        return sorted.reverse();
      } else {
        return sorted;
      }
    }

    return items;
  };

  const sortedItems = sortItems(filteredItems);

  let rowsFinal = sortedItems.slice(
    pagination.page * pagination.perPage,
    (pagination.page + 1) * pagination.perPage
  );

  return (
    <div>
      <div className="table-main-wrap">
        <div className="generic-list-seach-wrap">
          {props.extraHeaderItems && (
            <div className="generic-list-seach-wrap-row">
              {props.extraHeaderItems
                ? props.extraHeaderItems.map((x: JSX.Element, i: number) => {
                    return <span key={i}>{x}</span>;
                  })
                : []}
            </div>
          )}
          <div className="generic-list-seach-wrap-row">
            {!holdOn && props.disablePagination !== true && (
              <Paginator
                max={filteredItems.length}
                emitPage={(page: number, perPage: number) => {
                  setPagination({ page, perPage, paginating: true });
                  setTimeout(() => {
                    setPagination({ page, perPage, paginating: false });
                  }, 200);
                }}
              />
            )}
          </div>
          <div className="generic-list-seach-wrap-row">
            <SearchBox
              placeholder={"Filter text"}
              value={filterValue}
              onChange={(e: any) => {
                if (e && e.target) {
                  setFilterValue(e.target.value);
                }
              }}
              onKeyUp={(e: any) => {
                if (e.keyCode === 13) {
                  doSearch(filterValue);
                }
              }}
              onClear={() => {
                setFilterValue("");
                setActiveFilterValue("");
                doSearch("");
              }}
            />
            <div style={{ width: "0.5em" }}></div>
            <TeamsButton
              primary
              disabled={filterValue === ""}
              text={"Search"}
              onClick={() => {
                doSearch(filterValue);
              }}
            />
          </div>
        </div>
        <div className="basic-table-zone-wrap">
          <div className="basic-table-zone-wrap-inner">
            <table
              style={
                props.addPaddingColumn
                  ? { fontSize: "0.8em" }
                  : { width: "100%", fontSize: "0.8em" }
              }
            >
              <thead>
                <tr>
                  {columns.map((x: any, i: number) => {
                    let headerExtraRenders: (JSX.Element | null)[] = [];

                    if (
                      props.extraInHeaderItems &&
                      props.extraInHeaderItems.length > i
                    ) {
                      headerExtraRenders.push(props.extraInHeaderItems[i]);
                    }

                    if (x.fieldName) {
                      headerExtraRenders.push(getColumnSortingElement(i));
                    }

                    return (
                      <th
                        key={i}
                        style={{
                          position: "sticky",
                          top: "-10px",
                          zIndex: "10",
                        }}
                      >
                        <div
                          className="basic-table-header-cell"
                          onClick={() => {
                            if (x.fieldName) {
                              let index = i + 1;
                              let absIndex = Math.abs(sortOnColumn);
                              // if no sort or sort on different column
                              if (absIndex !== index) {
                                setSortOnColumn(index);
                                return;
                              }
                              // sort is on current index, go reverse
                              if (sortOnColumn === index) {
                                setSortOnColumn(-index);
                                return;
                              }
                              //sort is on current index already reverse
                              if (sortOnColumn === -index) {
                                setSortOnColumn(0);
                                return;
                              }
                            }
                          }}
                        >
                          {x.name && (
                            <div style={{ marginRight: "0.2em" }}>{x.name}</div>
                          )}
                          <div className="basic-table-custom-header">
                            {headerExtraRenders.map(
                              (x: JSX.Element | null, i: number) => {
                                return <span key={i}>{x}</span>;
                              }
                            )}
                          </div>
                        </div>
                      </th>
                    );
                  })}
                  {props.addPaddingColumn && (
                    <th
                      style={{
                        position: "sticky",
                        top: "-10px",
                        zIndex: "10",
                        width: "100%",
                      }}
                    >
                      <div
                        className="basic-table-header-cell"
                        style={{ width: "100%" }}
                      ></div>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {rowsFinal.map((x: any[], i: number) => {
                  let cells = [];
                  for (let j = 0; j < columns.length; j++) {
                    cells.push(extractItemByField(x, columns, j));
                  }

                  return (
                    <tr
                      key={i}
                      className={
                        "basic-table-row-data " +
                        (i % 2 === 0 ? "" : "basic-table-row-data-odd")
                      }
                    >
                      {cells.map((cell: JSX.Element, j: number) => {
                        return (
                          <td key={j}>
                            <div className="basic-table-data-cell">{cell}</div>
                          </td>
                        );
                      })}
                      {props.addPaddingColumn && (
                        <td>
                          <div className="basic-table-data-cell"></div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        {rowsFinal.length === 0 && (
          <div className="no-data-on-list">No data to display.</div>
        )}
      </div>
    </div>
  );
};

const FluentDataDable = (props: IFluentDataDableProps) => {
  const [rowData, setRowData] = useState<any[]>([]);
  const [columnsData, setColumnsData] = useState<any[]>([]);

  const getRawColumnItem = (
    name: string,
    dataType: string,
    keyPath: string,
    size: "small" | "medium" | "bigger" | "large" | "larger" | "wide",
    onRender?: (item: any) => JSX.Element
  ) => {
    let column = {
      name: name,
      dataType: dataType,
      fieldName: keyPath,
      size: size,
      onRender: onRender ? onRender : null,
    };
    return column;
  };

  const fromRWTDDataToColumn = (item: any, index: number) => {
    let header =
      item.clientName +
      (item.measureUnitShortName &&
      item.measureUnitShortName !== "Dimensionless"
        ? " (" + item.measureUnitShortName + ")"
        : "");

    let size: any = "bigger";
    if (header.length > 15) {
      size = "large";
    }
    if (header.length > 25) {
      size = "larger";
    }
    if (header.length > 35) {
      size = "wide";
    }

    return getRawColumnItem(
      header,
      fromIdPropertyTypeToType(item.idPropertyType),
      index.toString() + ".value",
      size
    );
  };

  const fromObjectToFluentUIColumns = () => {
    if (rowData && rowData.length > 0) {
      let columns: any[] = [];
      // buttons column
      if (props.actionColumn) {
        let header = props.actionColumnName ? props.actionColumnName : "";
        columns.push(
          getRawColumnItem(header, "", "", "medium", props.actionColumn)
        );
      }

      // header data
      let headers = rowData
        .filter((x: any) => x.rowNumber === -1)
        .sort((a: any, b: any) => {
          return a.ordinal - b.ordinal;
        });

      headers.forEach((x: any, i: number) => {
        columns.push(fromRWTDDataToColumn(x, i));
      });

      return columns;
    } else {
      return [];
    }
  };

  useEffect(() => {
    setRowData(props.rowData);
  }, [props.rowData]);

  useEffect(() => {
    setColumnsData(props.columns);
  }, [props.columns]);

  const getColumns = () => {
    return columnsData;
  };

  const getRows = () => {
    return rowData;
  };

  let extraProps = props.extraInnerProps ? props.extraInnerProps : {};
  let columns = getColumns();
  let rows = getRows();
  return (
    <div className="table-main-wrap">
      {props.isLoading && (
        <div className="spinner-wrap">
          <TeamsSpinner label="Loading..." />
        </div>
      )}
      {!props.isLoading && (
        <div>
          <BasicTable
            onLinkClick={props.onLinkClick}
            onPagination={(page: number, perPage: number) => {
              if (props.onPagination) {
                props.onPagination(page, perPage);
              }
            }}
            disablePagination={props.disablePagination}
            columns={columns}
            addPaddingColumn={props.addPaddingColumn}
            rows={rows}
            {...extraProps}
            extraInHeaderItems={props.extraInHeaderItems}
          />
        </div>
      )}
    </div>
  );
};

export default FluentDataDable;

//<GenericList columns={columns} items={rows} {...extraProps} />
