import { SpinButton, Spinner } from "@fluentui/react";
import "./TeamsSpinner.scss";
import { IsBrowserMode } from "../../App";
import { Spinner as SpinnerTeams } from "@fluentui/react-components";

export interface ITeamsSpinnerProps {
  label?: string;
}

const TeamsSpinner = (props: ITeamsSpinnerProps) => {
  return (
    <>
      {IsBrowserMode() && (
        <>
          <Spinner label={props.label} />
        </>
      )}
      {!IsBrowserMode() && (
        <>
          <SpinnerTeams label={props.label} />
        </>
      )}
    </>
  );
};

export default TeamsSpinner;
