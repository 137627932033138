import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { IsBrowserMode } from "../../App";
import { Button } from "@fluentui/react-components";
import "./TeamsButton.scss";

export interface ITeamsButtonProps {
  text: string;
  onClick: () => void;
  iconProps?: any;
  primary: boolean;
  disabled?: boolean;
  style?: any;
  className?: string;
}

const TeamsButton = (props: ITeamsButtonProps) => {
  return (
    <>
      {IsBrowserMode() && (
        <>
          {props.primary && (
            <PrimaryButton
              className={props.className}
              style={props.style}
              disabled={props.disabled}
              text={props.text}
              onClick={props.onClick}
              iconProps={props.iconProps}
            />
          )}
          {!props.primary && (
            <DefaultButton
              className={props.className}
              style={props.style}
              disabled={props.disabled}
              text={props.text}
              onClick={props.onClick}
              iconProps={props.iconProps}
            />
          )}
        </>
      )}
      {!IsBrowserMode() && (
        <>
          <Button
            className={props.className}
            appearance={props.primary ? "primary" : "secondary"}
            onClick={props.onClick}
          >
            {props.text}
          </Button>
        </>
      )}
    </>
  );
};

export default TeamsButton;
