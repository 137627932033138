import API_ROUTES from "../../APIRoutes";
import {
  IAssistantTask,
  IAssistantTaskInterface,
  IAssistantTaskInterfaceUpdate,
} from "../../Models/IAssistantTask";
import { AjaxService, IAPIRequest, IAPIResponse } from "../AjaxService";
import { BuildParameters } from "../ApiService";

const GenericController = {
  InfoOnAuthor: async (
    authorType: string,
    authorId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(API_ROUTES.GENERIC.INFO_ON_AUTHOR, [
      ["authorType", authorType],
      ["authorId", authorId],
    ]);
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  WriteLog: async (
    logLocation: string,
    logContent: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(API_ROUTES.GENERIC.WRITE_LOG, []);
    request.method = "POST";
    request.payload = { logLocation, logContent };
    request.headers = { "Content-Type": "application/json" };
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  GetSessionMigrationToken: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(
      API_ROUTES.GENERIC.GET_SESSION_MIAGRATION_TOKEN,
      []
    );
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  AskChatGPT: async (
    query: string,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(API_ROUTES.GENERIC.GET_CHAT_GPT_RESPONSE, [
      ["query", query],
    ]);
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
};

export default GenericController;
