import { Label } from "@fluentui/react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "./DoughnutChart.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

export interface IDoughnutChartProps {
  backgroundColor: string[];
  labels: string[];
  data: number[];
  title: string;
}

const DoughnutChart = (props: IDoughnutChartProps) => {
  return (
    <div className="main-chart-wrap">
      <Doughnut
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: "bottom",
              labels: {
                boxWidth: 10,
                padding: 10,
                font: {
                  size: 10,
                },
              },
            },
          },
        }}
        data={{
          labels: props.labels,
          datasets: [
            {
              label: props.title,
              data: props.data,
              backgroundColor: props.backgroundColor,
            },
          ],
        }}
      />
    </div>
  );
};

export default DoughnutChart;
