import React, { useEffect, useState } from "react";
import "./App.scss";
import AppLayout from "./AppLayout";
import { Providers, ProviderState } from "@microsoft/mgt-element";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import AuthenticationHelper from "./Utils/AuthenticationHelper";
import MicrosoftLogin from "./Components/MicrosoftLogin/MicrosoftLogin";
import { useDispatch, useSelector } from "react-redux";
import { GenericAction, GenericActions } from "./Redux/Generic/GenericAction";
import AppTeamsWrapper from "./AppTeamsWrapper";
import { useNavigate } from "react-router-dom";
import ApiService from "./Services/ApiService";
import { IAppDictionary } from "./Models/IAppDictionary";
import { GlobalState } from "./Redux/RootReducer";
import { Tools } from "./Utils/Tools";
import { IAppTabs, InitDynamicAppRoutes } from "./AppRoutes";
import { Spinner } from "@fluentui/react";
import { IsBrowserMode } from "./App";
import { RemoteLog } from "./Utils/DataSupportAPI";
import TeamsSpinner from "./Components/TeamsSpinner/TeamsSpinner";

function AppPreloader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token: string | undefined = useSelector(
    (state: any) => state.generic.token
  );
  const myTeamsAssistantToken: string | undefined = useSelector(
    (state: GlobalState) => state.generic.myTeamsAssistantToken
  );
  const appDictionaries: IAppDictionary[] = useSelector(
    (state: GlobalState) => state.generic.appDictionaries
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingDictionaries, setLoadingDictionaris] = useState<boolean>(true);

  const GetMTAToken = () => {
    if (token) {
      setLoading(true);
      RemoteLog("AppPreloader", "GetMTAToken");
      ApiService.SessionController.LoginWithAzureAD(token, (response) => {
        if (response.error === null) {
          RemoteLog("AppPreloader", "GetMTAToken done!");
          dispatch(GenericActions.setMTAToken(response.parsed.Token));
        }
        setLoading(false);
      });
    }
  };

  const GetAppDictionaries = () => {
    setLoadingDictionaris(true);
    ApiService.SessionController.GetAppDictionaries((response) => {
      if (response.error === null) {
        dispatch(GenericActions.setAppDictionaries(response.parsed));
      }
      setLoadingDictionaris(false);
    });
  };

  const ConcludeNavigation = () => {
    // go to default tab
    let AppTabs = InitDynamicAppRoutes(appDictionaries);
    let currentTab: IAppTabs | undefined = AppTabs.find((x: IAppTabs) =>
      window.location.pathname.includes(x.tabPath)
    );

    let activeTab: IAppTabs = AppTabs[0];

    if (currentTab) {
      activeTab = currentTab;
    }

    if (activeTab.tabChildRoutes.length > 0) {
      let child = activeTab.tabChildRoutes[0];
      navigate(child.path + (child.search ? "?" + child.search : ""));
    } else {
      navigate(activeTab.tabPath);
    }
  };

  useEffect(() => {
    if (appDictionaries.length > 0) {
      ConcludeNavigation();
    }
  }, [appDictionaries]);

  useEffect(() => {
    if (myTeamsAssistantToken) {
      GetAppDictionaries();
    }
  }, [myTeamsAssistantToken]);

  useEffect(() => {
    if (myTeamsAssistantToken == undefined) {
      GetMTAToken();
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <div>
      {(loading || loadingDictionaries) && (
        <div className="loading-main-wrapper">
          <TeamsSpinner />
        </div>
      )}
      {myTeamsAssistantToken && !loading && !loadingDictionaries && (
        <div>
          {IsBrowserMode() && <AppLayout />}
          {!IsBrowserMode() && <AppTeamsWrapper />}
        </div>
      )}
    </div>
  );
}

export default AppPreloader;
