import API_ROUTES from "../../APIRoutes";
import {
  IBusinessItemInterface,
  IBusinessItemInterfaceUpdate,
} from "../../Models/IBusinessItem";
import { AjaxService, IAPIRequest, IAPIResponse } from "../AjaxService";
import { BuildParameters } from "../ApiService";

const BusinessItemController = {
  GetBusinessItemMadeByMe: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = API_ROUTES.BUSINESS_ITEMS.GET_MADEBYME;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  GetBusinessItem: async (
    businessItemId?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(API_ROUTES.BUSINESS_ITEMS.GET, [
      ["businessItemId", businessItemId],
    ]);
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  InsertBusinessItem: async (
    itemToAdd: IBusinessItemInterface,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(API_ROUTES.BUSINESS_ITEMS.INSERT, []);
    request.method = "POST";
    request.payload = itemToAdd;
    request.headers = { "Content-Type": "application/json" };
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  UpdateBusinessItem: async (
    itemToUpdate: IBusinessItemInterfaceUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(API_ROUTES.BUSINESS_ITEMS.UPDATE, []);
    request.method = "PUT";
    request.payload = itemToUpdate;
    request.headers = { "Content-Type": "application/json" };
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  DeleteBusinessItem: async (
    businessItemId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.method = "DELETE";
    request.url = BuildParameters(API_ROUTES.BUSINESS_ITEMS.DELETE, [
      ["businessItemId", businessItemId],
    ]);
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
};

export default BusinessItemController;
