import { useSearchParams } from "react-router-dom";
import "./ScopesChartView.scss";
import { useEffect, useState } from "react";
import { IAssistantTask } from "../../Models/IAssistantTask";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import { INotification } from "../../Models/INotification";
import { IFileEntry } from "../../Models/IFileEntry";
import Loadable from "../../Components/Loadable/Loadable";
import { IBusinessItem } from "../../Models/IBusinessItem";
import TaskChartBox from "../../Components/TaskChartBox/TaskChartBox";
import { MessageBar, MessageBarType } from "@fluentui/react";
import TeamsButton from "../../Components/TeamsButton/TeamsButton";
import TasksTabPage from "../TasksTabPage/TasksTabPage";

const ScopesChartView = () => {
  const [searchParam] = useSearchParams();
  const scopeTarget: string = searchParam.get("scopeTarget") ?? "";

  const [taskView, setTaskView] = useState<string>("");

  const [reload, setReload] = useState<boolean>(false);
  const [activeTasks, setActiveTasks] = useState<IAssistantTask[]>([]);
  const [assistatTasks, setAssistatTasks] = useState<IAssistantTask[]>([]);
  const [loadingTasks, setLoadingTasks] = useState<boolean>(false);

  const DoReload = () => {
    setReload(true);
    setTimeout(() => {
      setReload(false);
    }, 200);
  };

  const GetAssistantTasks = () => {
    setLoadingTasks(true);
    ApiService.AssistantTaskController.GetAssistantTask(
      undefined,
      (response: IAPIResponse) => {
        if (response.error === null) {
          setAssistatTasks(response.parsed);
        }
        setLoadingTasks(false);
      }
    );
  };

  useEffect(() => {
    GetAssistantTasks();
  }, []);

  useEffect(() => {
    setActiveTasks(
      assistatTasks.filter(
        (task: IAssistantTask) => task.TaskField === scopeTarget
      )
    );
    DoReload();
  }, [scopeTarget, assistatTasks]);

  const GetActiveProcesses = () => {
    let activeProcesses: string[] = [];

    // get all processes
    activeTasks.forEach((task: IAssistantTask) => {
      if (activeProcesses.indexOf(task.TaskProcess) === -1) {
        activeProcesses.push(task.TaskProcess);
      }
    });

    return activeProcesses;
  };

  let activeProcessed = GetActiveProcesses();

  const TranferSession = (targetType: string) => {
    ApiService.GenericController.GetSessionMigrationToken(
      (response: IAPIResponse) => {
        if (response.error === null) {
          let targetUrl: string =
            "https://demoteams-integrations.r53.avvale.com/#/transferSession?redirectAfter=" +
            encodeURIComponent(targetType);
          targetUrl += "&migrationToken=" + response.parsed.Param;
          let a = document.createElement("a");
          a.href = targetUrl;
          a.target = "_blank";
          a.click();
        }
      }
    );
  };

  return (
    <div>
      {!taskView && (
        <Loadable
          isLoading={loadingTasks}
          content={
            <div>
              {!loadingTasks && !reload && (
                <div>
                  {activeProcessed.length === 0 && (
                    <div>
                      <MessageBar messageBarType={MessageBarType.info}>
                        You have no tasks for this scope: {scopeTarget}
                      </MessageBar>
                    </div>
                  )}
                  {activeProcessed.map((process: string, i: number) => {
                    return (
                      <TaskChartBox
                        key={i}
                        OnNewItem={() => {
                          TranferSession(
                            "/" +
                              process.toLocaleLowerCase().replaceAll(" ", "")
                          );
                        }}
                        OnButtonPressed={() => {
                          setTaskView(process);
                        }}
                        process={process}
                        tasks={activeTasks.filter(
                          (x: IAssistantTask) => x.TaskProcess === process
                        )}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          }
        />
      )}
      {taskView !== "" && (
        <div>
          <TeamsButton
            text="Back"
            onClick={() => {
              setTaskView("");
              GetAssistantTasks();
            }}
            primary
          />
          <br />
          <TasksTabPage scopeTarget={scopeTarget} processTarget={taskView} />
        </div>
      )}
    </div>
  );
};

export default ScopesChartView;
