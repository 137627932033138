import AjaxPipeline from "./AjaxPipeline";

export interface IAPIRequest {
  method: string;
  url: string;
  payload: any;
  headers: any;
  dontStringify: boolean;
  responseSkipToJson: boolean;
  disableTimeout: boolean;
  allowCache?: boolean;
}

export interface IAPIResponse {
  raw: any;
  parsed: any;
  error: string | null;
  skipped?: boolean;
}

export interface CustomResponseCodeHandler {
  code: number;
  action: (responseStatus: number) => void;
}

const applyDefaultHeaders = (requestHeaders: any) => {
  let defaultHeaders = {
    Authorization:
      "Bearer " + localStorage.getItem("myteams-assistant-sessionToken"),
  };
  return { ...requestHeaders, ...defaultHeaders };
};

const CustomResponseCodeHandlers: CustomResponseCodeHandler[] = [];

// utility to get a request object
const GetEmptyRequest = () => {
  return {
    method: "GET",
    url: "",
    payload: null,
    headers: {},
    responseSkipToJson: false,
    dontStringify: false,
    disableTimeout: false,
    allowCache: false,
  } as IAPIRequest;
};

// this function returns the status text if the status code is >= 300, else null
const GetRequestError = (response: IAPIResponse) => {
  if (response.parsed && response.parsed.message) {
    return response.parsed.message;
  }

  if (response.parsed && response.parsed.title) {
    return response.parsed.title;
  }

  let rawRequest = response.raw;
  if (rawRequest) {
    let rawstatus = rawRequest.status;
    let rawText = rawRequest.statusText;
    if (rawstatus >= 300) {
      return rawText;
    }
  }

  return response.error;
};

const APICall = async (apiRequest: IAPIRequest) => {
  // add some delay on local network to allow "loading" to be shown
  if (window.location.origin.includes("localhost")) {
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }

  // get a response
  let response = await ExecuteAPICall(apiRequest);

  // add some delay on local network to allow "loading" to be shown
  if (window.location.origin.includes("localhost")) {
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }

  // validate the result
  response.error = GetRequestError(response);

  // this loop handles automatically specific api codes
  for (let i = 0; i < CustomResponseCodeHandlers.length; i++) {
    if (response.raw) {
      let status = response.raw.status;

      if (
        CustomResponseCodeHandlers[i].code === status ||
        CustomResponseCodeHandlers[i].code === 0
      ) {
        CustomResponseCodeHandlers[i].action(status);
        break;
      }
    }
  }

  return response;
};

export const getBaseUrl = () => {
  let localServer = "https://localhost:7006";
  let remoteServer = "https://demoteams-be.r53.avvale.com";
  return window.location.origin.includes("localhost")
    ? localServer
    : remoteServer;
};

const ExecuteAPICall = async (apiRequest: IAPIRequest) => {
  // set default parameters
  let parameters: any = {
    method: apiRequest.method, // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  };

  // set payload if exists
  if (apiRequest.payload) {
    if (apiRequest.dontStringify) {
      parameters["body"] = apiRequest.payload; // e.g: for file upload
    } else {
      parameters["body"] = JSON.stringify(apiRequest.payload); // body data type must match "Content-Type" header
    }
  }

  // add custom headers, if any, else use the classic application/json header
  parameters["headers"] = applyDefaultHeaders(apiRequest.headers);

  const response = await AjaxPipeline.FetchPipeline(
    getBaseUrl() + apiRequest.url,
    parameters,
    apiRequest.disableTimeout
  );

  let parsedResponse: IAPIResponse = {
    raw: response,
    parsed: null,
    error: null,
  };

  // try read the response
  if (response) {
    if (!apiRequest.responseSkipToJson) {
      try {
        parsedResponse.parsed = await response.json();
      } catch (e) {
        console.error((e as any).message);
      }
    }

    // utility dev print
    let developerLog: any = { ...parameters, url: apiRequest.url };
    developerLog["response"] = parsedResponse;
    console.log("API", apiRequest.url, developerLog);
  } else {
    console.log("API skipped", apiRequest.url);
    parsedResponse["skipped"] = true;
  }

  return parsedResponse;
};

export const AjaxService = {
  GetEmptyRequest,
  CustomResponseCodeHandlers,
  APICall,
};
