import { useEffect, useState } from "react";
import "./TasksTabPage.scss";
import { INotification } from "../../Models/INotification";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import Loadable from "../../Components/Loadable/Loadable";
import FluentDataDable from "../../Components/FluentDataDable/FluentDataDable";
import {
  IAssistantTask,
  IAssistantTaskInterfaceUpdate,
} from "../../Models/IAssistantTask";
import CallToActionButton from "../../Components/CallToActionButton/CallToActionButton";
import PriorityBubble from "../../Components/PriorityBubble/PriorityBubble";
import { IAppDictionary } from "../../Models/IAppDictionary";
import { useSelector } from "react-redux";
import { GlobalState } from "../../Redux/RootReducer";
import { InjectAuthorData } from "../../Utils/DataSupportAPI";
import TeamsDataGrid from "../../Components/TeamsDataGrid/TeamsDataGrid";
import TeamsTable from "../../Components/TeamsTable/TeamsTable";
import TeamsButton from "../../Components/TeamsButton/TeamsButton";

const TasksTabPage = (props: {
  scopeTarget?: string;
  processTarget?: string;
  onlyCta?: boolean;
}) => {
  const [assistatTasksActive, setAssistatTasksActive] = useState<
    IAssistantTask[]
  >([]);
  const [assistatTasks, setAssistatTasks] = useState<IAssistantTask[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(false);

  const reload = () => {
    setRefresh(true);
    setTimeout(() => {
      setRefresh(false);
    }, 500);
  };

  const GetAssistantTasks = () => {
    setLoading(true);
    ApiService.AssistantTaskController.GetAssistantTask(
      undefined,
      async (response: IAPIResponse) => {
        if (response.error === null) {
          setAssistatTasks((await InjectAuthorData(response.parsed)) as any);
        }
        setLoading(false);
      }
    );
  };

  const loadActiveTasks = (tasks: IAssistantTask[]) => {
    let filteredTasks = [...tasks];
    if (props.scopeTarget) {
      filteredTasks = filteredTasks.filter(
        (task: IAssistantTask) => task.TaskField === props.scopeTarget
      );
    }

    if (props.processTarget) {
      filteredTasks = filteredTasks.filter(
        (task: IAssistantTask) => task.TaskProcess === props.processTarget
      );
    }

    if (props.onlyCta) {
      filteredTasks = filteredTasks.filter(
        (x: IAssistantTask) =>
          x.TaskCallToAction.split(",").filter((x) => x).length > 0 &&
          x.TaskStatus === "Assigned"
      );
    }

    setAssistatTasksActive(filteredTasks);
    reload();
  };

  useEffect(() => {
    loadActiveTasks(assistatTasks);
  }, [props.scopeTarget, assistatTasks]);

  useEffect(() => {
    GetAssistantTasks();
  }, []);

  const handleAction = (
    action: string,
    item: IAssistantTaskInterfaceUpdate
  ) => {
    if (action === "Delete") {
      ApiService.AssistantTaskController.DeleteAssistantTask(
        item.TaskId,
        (response: IAPIResponse) => {
          if (response.error === null) {
            GetAssistantTasks();
          }
        }
      );
    }
    if (action === "Edit") {
    }
    if (action === "Approve") {
      let taskToUpdate: IAssistantTaskInterfaceUpdate = { ...item };
      taskToUpdate.TaskStatus = "Approved";
      ApiService.AssistantTaskController.UpdateAssistantTask(
        taskToUpdate,
        (response: IAPIResponse) => {
          if (response.error === null) {
            GetAssistantTasks();
          }
        }
      );
    }
    if (action === "Reject") {
      let taskToUpdate: IAssistantTaskInterfaceUpdate = { ...item };
      taskToUpdate.TaskStatus = "Rejected";
      ApiService.AssistantTaskController.UpdateAssistantTask(
        taskToUpdate,
        (response: IAPIResponse) => {
          if (response.error === null) {
            GetAssistantTasks();
          }
        }
      );
    }
  };

  return (
    <div>
      <Loadable
        isLoading={loading || refresh}
        content={
          <div>
            <TeamsTable
              handleLinkClick={(item: any, link: string) => {
                // new url
                let targetUrl =
                  link + "?itemId=" + item.TaskId + "&itemType=AssistantTask";

                ApiService.GenericController.GetSessionMigrationToken(
                  (response: IAPIResponse) => {
                    if (response.error === null) {
                      let a = document.createElement("a");
                      a.href =
                        targetUrl + "&migrationToken=" + response.parsed.Param;
                      a.target = "_blank";
                      a.click();
                    }
                  }
                );
              }}
              columns={[
                {
                  name: "Title",
                  dataType: "string",
                  fieldName: "TaskTitle",
                  size: "medium",
                },
                {
                  name: "Description",
                  dataType: "string",
                  fieldName: "TaskDescription",
                  size: "medium",
                },
                {
                  name: "Link",
                  dataType: "link",
                  fieldName: "TaskExternalSystemLink",
                  size: "medium",
                },
                {
                  name: "Status",
                  dataType: "string",
                  fieldName: "TaskStatus",
                  size: "medium",
                },
                {
                  name: "Priority",
                  dataType: "string",
                  fieldName: "PriorityLabel",
                  size: "medium",
                  onRender: (item: IAssistantTask) => {
                    return (
                      <div>
                        <PriorityBubble priority={item.TaskPriority} />
                      </div>
                    );
                  },
                },
                {
                  name: "Author",
                  dataType: "string",
                  fieldName: "CreatedByEmail",
                  size: "medium",
                },
                {
                  name: "Created On",
                  dataType: "date/time",
                  fieldName: "CreatedDate",
                  size: "medium",
                },
                {
                  name: "Updated On",
                  dataType: "date/time",
                  fieldName: "UpdatedDate",
                  size: "medium",
                },
                {
                  name: "Action",
                  dataType: "string",
                  fieldName: "TaskCallToAction",
                  size: "medium",
                  onRender: (item: IAssistantTask) => {
                    return (
                      <div>
                        <CallToActionButton
                          disabled={item.TaskStatus !== "Assigned"}
                          onActionClick={(action: string) => {
                            handleAction(action, item as any);
                          }}
                          targetElementId={item.TaskId}
                          currentCallToAction={item.TaskCallToAction}
                        />
                      </div>
                    );
                  },
                },
              ]}
              rowData={assistatTasksActive}
            />
          </div>
        }
      />
    </div>
  );
};

export default TasksTabPage;
