import { Label, Pivot, PivotItem } from "@fluentui/react";
import "./BetterPivot.scss";
import { Tools } from "../../Utils/Tools";

export interface IBetterPivotProps {
  voices: { key: string; text: string; iconName?: string }[];
  onSelectedKey?: (key: string) => void;
}

const BetterPivot = (props: IBetterPivotProps) => {
  return (
    <div style={{ maxWidth: "100%", overflow: "auto", overflowY: "hidden" }}>
      <Pivot
        aria-label="Basic Pivot"
        onLinkClick={(item: PivotItem | undefined) => {
          if (item && props.onSelectedKey) {
            let result: string = (item as any).key.replace(".$", "");
            result = result.replace(/[0-9]/g, "");
            props.onSelectedKey(result);
          }
        }}
      >
        {props.voices.map(
          (x: { key: string; text: string; iconName?: string }) => {
            return (
              <PivotItem
                key={x.key}
                itemIcon={x.iconName}
                headerText={x.text}
              ></PivotItem>
            );
          }
        )}
      </Pivot>
    </div>
  );
};

export default BetterPivot;
