import { IsBrowserMode } from "../../App";
import { IAPIResponse } from "../../Services/AjaxService";
import ApiService from "../../Services/ApiService";
import FluentDataDable from "../FluentDataDable/FluentDataDable";
import TeamsDataGrid from "../TeamsDataGrid/TeamsDataGrid";
import "./TeamsTable.scss";

export interface ITeamsTableProps {
  rowData: any[];
  columns: any[];
  handleLinkClick?: (item: any, link: string) => void;
}

const TeamsTable = (props: ITeamsTableProps) => {
  const tableHandleClick = (item: any, link: string) => {
    if (props.handleLinkClick) {
      props.handleLinkClick(item, link);
    } else {
      let a = document.createElement("a");
      a.href = link;
      a.target = "_blank";
      a.click();
    }
  };

  return (
    <>
      {IsBrowserMode() && (
        <>
          <FluentDataDable
            onLinkClick={tableHandleClick}
            columns={props.columns}
            rowData={props.rowData}
          />
        </>
      )}
      {!IsBrowserMode() && (
        <TeamsDataGrid
          onLinkClick={tableHandleClick}
          columns={props.columns}
          rowData={props.rowData}
        />
      )}
    </>
  );
};

export default TeamsTable;
