import { TextField } from "@fluentui/react";
import { IsBrowserMode } from "../../App";
import "./TeamsTextField.scss";
import { Input, InputOnChangeData } from "@fluentui/react-components";

export interface ITeamsTextFieldProps {
  onChange: (value: string) => void;
  placeholder?: string;
  onKeyDown?: (event: any) => void;
  style?: any;
}

const TeamsTextField = (props: ITeamsTextFieldProps) => {
  return (
    <>
      {IsBrowserMode() && (
        <>
          <TextField
            style={props.style}
            onChange={(e, t) => {
              if (t !== undefined) {
                props.onChange(t);
              }
            }}
            onKeyDown={props.onKeyDown}
            placeholder={props.placeholder ?? "Enter text"}
          />
        </>
      )}
      {!IsBrowserMode() && (
        <Input
          style={props.style}
          placeholder={props.placeholder ?? "Enter text"}
          onKeyDown={props.onKeyDown}
          onChange={(e: any, data: InputOnChangeData) => {
            props.onChange(data.value);
          }}
        />
      )}
    </>
  );
};

export default TeamsTextField;
