import API_ROUTES from "../../APIRoutes";
import {
  INotificationInterface,
  INotificationInterfaceUpdate,
} from "../../Models/INotification";
import { AjaxService, IAPIRequest, IAPIResponse } from "../AjaxService";
import { BuildParameters } from "../ApiService";

const NotificationController = {
  GetNotificationMadeByMe: async (
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = API_ROUTES.NOTIFICATIONS.GET_MADEBYME;
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  GetNotification: async (
    notificationId?: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(API_ROUTES.NOTIFICATIONS.GET, [
      ["notificationId", notificationId],
    ]);
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  InsertNotification: async (
    notificationToAdd: INotificationInterface,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(API_ROUTES.NOTIFICATIONS.INSERT, []);
    request.method = "POST";
    request.payload = notificationToAdd;
    request.headers = { "Content-Type": "application/json" };
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  UpdateNotification: async (
    notificationToUpdate: INotificationInterfaceUpdate,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.url = BuildParameters(API_ROUTES.NOTIFICATIONS.UPDATE, []);
    request.method = "PUT";
    request.payload = notificationToUpdate;
    request.headers = { "Content-Type": "application/json" };
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
  DeleteNotification: async (
    notificationId: number,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = AjaxService.GetEmptyRequest();
    request.method = "DELETE";
    request.url = BuildParameters(API_ROUTES.NOTIFICATIONS.DELETE, [
      ["notificationId", notificationId],
    ]);
    let response = await AjaxService.APICall(request);
    if (callback && !response.skipped) {
      callback(response);
    }
    return response;
  },
};

export default NotificationController;
