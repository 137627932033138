import { useState } from "react";
import "./DebugPage.scss";

const DebugPage = (props: { onReset: () => void }) => {
  const [areacontent, setAreaContent] = useState<string>("");

  return (
    <div style={{ padding: "1em" }}>
      <button onClick={props.onReset}>back</button>
      <button
        onClick={() => {
          (window.location as any).reload(true);
        }}
      >
        RELOAD
      </button>

      <br />
      <textarea
        className="debug-text-area"
        onChange={(e) => {
          setAreaContent(e.target.value);
        }}
      ></textarea>
      <br />
      <button
        onClick={() => {
          try {
            let result = eval(areacontent);
            (document.getElementById("eval-result") as any).innerHTML =
              JSON.stringify(result, null, 4);
          } catch (e: any) {
            (document.getElementById("eval-result") as any).innerHTML =
              e.message;
          }
        }}
      >
        eval
      </button>
      <br />

      <div id="eval-result" className="debug-output"></div>
    </div>
  );
};

export default DebugPage;
