import { Dropdown, IDropdownOption, PrimaryButton } from "@fluentui/react";
import { IAssistantTask } from "../../Models/IAssistantTask";
import { Tools } from "../../Utils/Tools";
import DoughnutChart from "../DoughnutChart/DoughnutChart";
import "./TaskChartBox.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { APP_ROUTE_NAMES } from "../../AppRoutes";
import TeamsButton from "../TeamsButton/TeamsButton";
import TeamsPivot from "../TeamsPivot/TeamsPivot";
import { Label } from "@fluentui/react-components";

export interface ITaskChartBoxProps {
  tasks: IAssistantTask[];
  process: string;
  OnNewItem: () => void;
  OnButtonPressed: () => void;
}

const NavOptions: any[] = [
  {
    key: "statuspage",
    text: "Status",
  },
  {
    key: "prioritypage",
    text: "Priority",
  },
];

const TaskChartBox = (props: ITaskChartBoxProps) => {
  const navigate = useNavigate();
  const [viewType, setViewType] = useState<string>(NavOptions[0].key);
  const [viewTitle, setViewTitle] = useState<string>(NavOptions[0].text);
  return (
    <div className="task-chart-box-main-card">
      <div className="task-chart-box-title-line">
        <Label style={{ fontSize: "1.2em" }}>{props.process}</Label>
      </div>
      <div className="task-chart-box-content">
        <TeamsPivot
          onSelectedKey={(key: string) => {
            setViewType(key);
            setViewTitle(
              NavOptions.find((x: any) => x.key === key)?.text ?? "Status"
            );
          }}
          voices={NavOptions.map((x: any) => {
            return { key: x.key, text: x.text };
          })}
        />
        <table className="gap-col-table">
          <thead />
          <tbody>
            <tr>
              <td>
                <Label style={{ opacity: "0.8" }}>Event {viewTitle}</Label>
              </td>
              <td>
                <Label style={{ opacity: "0.8" }}>My Tasks</Label>
              </td>
            </tr>
            <tr>
              <td>
                {viewType === NavOptions[0].key && (
                  <DoughnutChart
                    title={""}
                    labels={["Approved", "Assigned", "Rejected"]}
                    data={[
                      props.tasks.filter(
                        (x: IAssistantTask) => x.TaskStatus === "Approved"
                      ).length,
                      props.tasks.filter(
                        (x: IAssistantTask) => x.TaskStatus === "Assigned"
                      ).length,
                      props.tasks.filter(
                        (x: IAssistantTask) => x.TaskStatus === "Rejected"
                      ).length,
                    ]}
                    backgroundColor={["#36A2EB", "#FFCE56", "#FF6384"]}
                  />
                )}
                {viewType === NavOptions[1].key && (
                  <DoughnutChart
                    title={""}
                    labels={["Critical", "High", "Medium", "Low"]}
                    data={[
                      props.tasks.filter(
                        (x: IAssistantTask) =>
                          x.PriorityLabel === "Critical" &&
                          x.TaskStatus === "Assigned"
                      ).length,
                      props.tasks.filter(
                        (x: IAssistantTask) =>
                          x.PriorityLabel === "High" &&
                          x.TaskStatus === "Assigned"
                      ).length,
                      props.tasks.filter(
                        (x: IAssistantTask) =>
                          x.PriorityLabel === "Medium" &&
                          x.TaskStatus === "Assigned"
                      ).length,
                      props.tasks.filter(
                        (x: IAssistantTask) =>
                          x.PriorityLabel === "Low" &&
                          x.TaskStatus === "Assigned"
                      ).length,
                    ]}
                    backgroundColor={[
                      "#FF6384",
                      "#e2e26a",
                      "#36A2EB",
                      "#ccfdcc",
                    ]}
                  />
                )}
              </td>
              <td>
                <Label
                  style={{
                    fontSize: "3em",
                    opacity: "0.8",
                    textAlign: "center",
                  }}
                >
                  {viewType === NavOptions[0].key && props.tasks.length}
                  {viewType === NavOptions[1].key &&
                    props.tasks.filter(
                      (x: IAssistantTask) => x.TaskStatus === "Assigned"
                    ).length}
                </Label>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="under-buttons">
          <TeamsButton
            primary
            onClick={() => {
              props.OnNewItem();
            }}
            text="New Item"
          />
          <TeamsButton
            primary
            onClick={() => {
              props.OnButtonPressed();
            }}
            text="View details"
          />
        </div>
      </div>
    </div>
  );
};

export default TaskChartBox;
